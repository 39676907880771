import React, { useEffect, useState } from "react";
import InputForm from "../../components/inputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  userDetails,
  clearStateData,
  updateUser,
} from "../../store/slice/categories";
import { useNavigate, useParams } from "react-router-dom";

export default function AddUser(props) {
  const [inputData, setInputData] = useState({});
  const [phoneData, setPhoneData] = useState({});
  const { userData, isLoading } = useSelector((state) => state.categories);

  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const { id } = useParams();
  const [inputError, setInputError] = useState({});

  useEffect(() => {
    dispatch(clearStateData());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(userDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (userData) {
      const phoneNumber = userData.countryCode + userData.phoneNumber;
      // setInputData(userData);
      setInputData((inputData) => ({
        ...inputData,
        ...userData,
        usersId: userData._id,
      }));
      setPhoneData(JSON.stringify(phoneNumber));
    }
  }, [userData]);

  const addInput = [
    {
      title: "Name",
      inputType: "AdminInput",
      isRequired: true,
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          fullName: val,
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            fullName: "",
          }));
        }
      },
      colNum: 6,
      value: inputData?.fullName,
      formError: inputError.fullName,
    },
    {
      title: "Email",
      inputType: "AdminInput",
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          email: val,
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            email: "",
          }));
        }
      },
      colNum: 6,
      value: inputData?.email,
      formError: inputError.email,
    },
    {
      title: "Phone Number",
      inputType: "PhoneInputComponent",
      onChange: (val, inputdata) => {
        setPhoneData(val);
        setInputData((inputData) => ({
          ...inputData,
          phoneNumber: val.slice(inputdata?.dialCode?.length),
          countryCode: `+${inputdata?.dialCode}`,
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            phoneNumber: "",
          }));
        }
      },
      colNum: 6,
      value: `${phoneData}`,
      formError: inputError.phoneNumber,
    },
    {
      title: "Register User By",
      inputType: "AdminRadio",
      disabled:id?true:false,
      isRequired: true,
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          loginType: Number(val),
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            loginType: "",
          }));
        }
      },
      colNum: 6,
      value:Number(inputData?.loginType),
      formError: inputError.loginType,
      radioBtnData: [
        { id: 1, label: "Email" },
        { id: 2, label: "Phone" },
      ],
    },
  ];

  const removeProfile = () => {
    removeFileUpload();
  };

  const removeFileUpload = () => {
    setInputData((inputData) => ({
      ...inputData,
      categoryImage: "",
      fileName: "",
    }));
  };

  const handleSubmit = () => {
    const validEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );

    if (!inputData.fullName) {
      setInputError((inputError) => ({
        ...inputError,
        fullName: "Please enter name",
      }));
      return;
    }
    if (inputData.loginType === "1" && !inputData.email) {
      setInputError((inputError) => ({
        ...inputError,
        email: "Please enter email",
      }));
      return;
    }
    if (
      !inputData?.email.includes("@") ||
      !inputData?.email.includes(".") ||
      !validEmail.test(inputData.email)
    ) {
      setInputError((inputError) => ({
        ...inputError,
        email: "Please enter a valid email",
      }));
    }
    if (inputData.loginType === "2" && !inputData.phoneNumber) {
      setInputError((inputError) => ({
        ...inputError,
        phoneNumber: "Please enter phone number",
      }));
      return;
    }
    if (!inputData.loginType) {
      setInputError((inputError) => ({
        ...inputError,
        loginType: "Please select user register type",
      }));
      return;
    }

    if (id) {
      // const data ={
      //   fullName:inputData.fullName,
      //   userId:inputData._id
      // }
      dispatch(updateUser(inputData, navigate));
    } else {
      dispatch(addUser(inputData, navigate));
    }
  };

  const handleBack = () => {
    navigate("/dashboard/Users");
    dispatch(clearStateData());
  };

  return (
    <InputForm
      formTitle={"Add User"}
      removeProfile={removeProfile}
      inputData={addInput}
      btnSave={handleSubmit}
      isLoading={isLoading}
      btnBack={handleBack}
    />
  );
}
