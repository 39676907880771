import { createSlice } from "@reduxjs/toolkit";
import api from "../../services";
import { SKILIIAPI, SERVER_URL } from "../../services/apiConstant";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

const slice = createSlice({
  name: "auth",
  initialState: {
    userData: null,
    message: "",
    isLoading: false,
  },
  reducers: {
    apiFetching: (state) => {
      state.isLoading = true;
    },
    userDataSuccess: (state, action) => {
      state.userData = action.payload.data;
      state.isLoading = false;
    },
    userDataFailed: (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
    },
    apiFailed: (state) => {
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

/**********************ACTIONS************************************************ */
export const login = (requestParams, navigate) => async (dispatch) => {
  dispatch(apiFetching());
  api
    .post(`${SERVER_URL}${SKILIIAPI.auth.login}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        secureLocalStorage.setItem(
          process.env.REACT_APP_TOKEN_STORAGE_KEY,
          result.token
        );
        secureLocalStorage.setItem(
          process.env.REACT_APP_AUTH_API_KEY,
          result.token
        );
        secureLocalStorage.setItem(
          process.env.REACT_APP_USER_STORAGE_KEY,
          result
        );
        secureLocalStorage.setItem(
          process.env.REACT_APP_AUTH_STORAGE_KEY,
          true
        );
        console.log('fsdfsdfsdf',process.env.REACT_APP_AUTH_STORAGE_KEY);

        navigate("/dashboard/app", true);
        // dispatch(userDataSuccess(result));
        // dispatch(apiFailed());
      } else {
        dispatch(userDataFailed(result.error));
        toast.error(response.data.message);
        dispatch(apiFailed());
      }
    })
    .catch((err) => {
      console.log(err)
      dispatch(apiFailed());
    });
};

export const forgotPassword = (requestParams) => async (dispatch) => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.auth.forgotPassword}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
      } else {
        dispatch(userDataFailed(result.error));
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const resetPassword = (requestParams, navigate) => async (dispatch) => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.auth.resetPassword}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
        navigate("/", true);
      } else {
        dispatch(userDataFailed(result.error));
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const resetPasswordForAdmin = (requestParams, navigate) => async (dispatch) => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.auth.resetPassword}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(result.message);
        navigate("/");
      } else {
        dispatch(userDataFailed(result.error));
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

const { apiFetching, userDataFailed, apiFailed, userDataSuccess } =
  slice.actions;
