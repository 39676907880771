import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";

export default function AdminRadio(props) {
  const {
    title,
    radioBtnData,
    formError,
    value,
    handleChange,
    disabled
  } = props;

  return (
    <>
      <FormLabel id="demo-row-radio-buttons-group-label">{title}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        // value={value}
        onChange={(e) => handleChange(e.target.value)}
      >
        {radioBtnData &&
          radioBtnData.map((item, i) => {
            return (
              <FormControlLabel
                value={item.id}
                disabled={disabled}
                checked={value === item.id}
                control={<Radio />}
                label={item.label}
              />
            );
          })}
      </RadioGroup>
      <Typography sx={{ color: "red", fontSize: 12, ml: 1 }}>
        {formError ? formError : ""}
      </Typography>
    </>
  );
}
