import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { color } from "../../theme/color";
import defaultProfile from "../../assets/img/default.jpg";
import Filter from "./subcomponents/filter";
import { Box } from "@mui/material";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getStripeAccounts,
  deleteStripeAccount,
  userAccountSuspend,
  loadingStart,
} from "../../store/slice/categories";
import ConfirmDialog from "../../components/adminDialog/confirmDialog";
import { Card } from "@mui/material";
import FilterDialog from "../../components/adminDialog/userFilterDialog";
import UserFilterDialog from "../../components/adminDialog/userFilterDialog";
import ViewdetailsDialog from "../../components/adminDialog/viewdetailsDialog";
import SpinnerComponent from "../../components/spinnerComponent";
import { Link, useNavigate } from "react-router-dom";
import AdminButton from "../../components/adminButton";
export default function ConnectedAccount() {
  var page = 1;
  var perPage = 10;
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const { connectedAccountsListData, totalConnectedAccountsCount, isLoading } = useSelector(
    (state) => state.categories
  );
  const [totalCount, setTotalCount] = useState();
  const [pagecheck, setPageCheck] = useState(1);
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [filterShow, setFilterShow] = useState(false);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortColumn, setSortColumn] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [showDialog, setShowDialog] = useState(null);
  const [showUserDialog, setshowUserDialog] = useState(null);

  const handleClick = (tableMeta, event) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(tableMeta.rowIndex);
  };

  function handleClose() {
    setAnchorEl(null);
  };

  function previousBtn() {
    // setAnchorEl(null);
    page = pagecheck - 1;
    setPageCheck(page);
    fetchConnectedAccounts(page, perPageRows, sortColumn, sortOrder, searchValue);
  };

  const nextBtn = () => {
    page = pagecheck + 1;
    setPageCheck(page);
    fetchConnectedAccounts(page, perPageRows, sortColumn, sortOrder, searchValue);
  };

  useEffect(() => {
    fetchConnectedAccounts(page);
    dispatch(loadingStart());
  }, []);

  useEffect(() => {
    setTotalCount(totalConnectedAccountsCount);
  }, [totalConnectedAccountsCount]);

  useEffect(() => {
    fetchConnectedAccounts(page, perPageRows, sortColumn, sortOrder, searchValue);
  }, [searchValue]);

  const fetchConnectedAccounts = (page, numberOfRows) => {
    var data = {
      page: page ? page : 1,
      limit: numberOfRows ? numberOfRows : perPageRows,
      search: searchValue.searchValue,
    };

    console.log('data----', data);

    dispatch(getStripeAccounts(data));
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDelete = (value) => {
    const data = {
      page: page,
      limit: perPageRows,
    };
    dispatch(deleteStripeAccount(value, fetchConnectedAccounts, data));
  };

  const handleSuspendaccount = (value) => {
    dispatch(userAccountSuspend(value));
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/users/edit/${id}`);
  };

  const columns = [

    {
      name: "email",
      label: "Account",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? value : "-"} { }</Typography>
          );
        },
      },
    },

    {
      name: "id",
      label: "Connect Id",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? value : "-"} { }</Typography>
          );
        },
      },
    },
    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{
              fontSize: 12, background: '#00ff0a70',
              width: 'max-content',
              color: 'green',
              padding: '4px',
              borderRadius: '7px'
            }
            } > Complete</Typography>
          );
        },
      },
    },
    {
      name: "userUid",
      label: "Action",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Button
                onClick={(event) => handleClick(tableMeta, event)}
                aria-describedby={id}
              >
                <Icon
                  icon="ph:dots-three-circle-vertical-fill"
                  color={color.main}
                  width="30"
                  height="30"
                />
              </Button>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        const id = connectedAccountsListData[selectedRowIndex].id;
                        // handleDelete(id);
                        setShowDialog((showDialog) => ({
                          ...showDialog,
                          show: true,
                          data: id,
                          type: "delete",
                          title: "Delete Account" + ' (' + id + ')',
                          content: "Are you sure you want to delete this account?",
                        }));
                      }}
                    >
                      <ListItemIcon>
                        <Icon
                          icon="ic:baseline-delete"
                          color="red"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Delete"
                        style={{ marginLeft: "-1rem" }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
            </>
          );
        },
      },
    },
  ];

  const options = {
    count: totalCount,
    filter: false,
    pagination: false,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "multiselect",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: false,
    rowsPerPage: 10,
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Total item per page ",
        displayRows: "of",
      },
      body: {
        noMatch: "No Record Found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onColumnSortChange: (changedColumn, direction) => {
      let sortingOrder = direction === "asc" ? 1 : -1;
      setSortOrder(sortingOrder);
      setSortColumn(changedColumn);
      fetchConnectedAccounts(page, "", changedColumn, sortingOrder);
    },
    onChangePage(currentPage) {
      if (currentPage >= pagecheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        fetchConnectedAccounts(page, "", "", "");
      } else {
        if (currentPage > 0) {
          page = currentPage + 1;
        }
        setPageCheck(currentPage);
        fetchConnectedAccounts(page, "", "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      //localStorage.setItem("socialListRowPerPage", numberOfRows);
      setNumberOfRows(numberOfRows);
      fetchConnectedAccounts(page, numberOfRows, "", "");
    },
  };

  return (
    <>
      {!isLoading ? (
        <div className="App wrapper">
          {/* <Filter
            filterBtn={false}
            endIcon={<Icon icon="ic:outline-plus" color="black" />}
            openFilterDialog={() => setFilterShow(true)}
            addBtn={false}
            addTitle="Add User"
            searchtitle="Search Here"
            path={"/dashboard/addUser"}
            onSearchChange={(val) => {
              setSearchValue((searchValue) => ({
                ...searchValue,
                searchValue: val,
              }));
            }}
            value={searchValue.searchValue}
          /> */}

          {connectedAccountsListData && connectedAccountsListData.length > 0 ? (
            <>
              <MUIDataTable
                title={"Connected account List"}
                data={connectedAccountsListData}
                columns={columns}
                options={options}
              />
            </>
          ) : (
            <>
              <Card
                style={{
                  textAlign: "center",
                  backgroundColor: "rgb(243 241 241)",
                }}
              >
                <Typography sx={{ fontSize: 24, fontWeight: 500, py: "3rem" }}>
                  No Record Found
                </Typography>
              </Card>
            </>
          )}
        </div>
      ) : (
        ""
      )}

      {showDialog && (
        <ConfirmDialog
          open={showDialog.show}
          handleClose={() => {
            setShowDialog((showDialog) => ({
              ...showDialog,
              show: false,
            }));
          }}
          title={showDialog.title}
          content={showDialog.content}
          handleSubmit={() => {
            setShowDialog((showDialog) => ({
              ...showDialog,
              show: false,
            }));
            handleClose();
            if (showDialog.type === "suspendaccount") {
              handleSuspendaccount(showDialog.data);
            } else {
              handleDelete(showDialog.data);
            }
          }}
        />
      )}

      <UserFilterDialog
        open={filterShow}
        handleClose={() => setFilterShow(false)}
        handleSave={(val) =>
          setSearchValue((searchValue) => ({
            ...searchValue,
            ...val,
          }))
        }
      />

      <SpinnerComponent open={isLoading} />

      {showUserDialog && (
        <ViewdetailsDialog
          open={showUserDialog.show}
          handleClose={() => {
            setshowUserDialog((showUserDialog) => ({
              ...showUserDialog,
              show: false,
            }));
          }}
          data={showUserDialog.data}
        />
      )}

      <>
        {!isLoading ?
          <div style={{
            display: 'flex',
            float: 'right',
            marginTop: '13px'
          }}>
            <Grid item xs={12} md={2} onClick={pagecheck == 1 ? null : previousBtn} style={{
              width: 'max-content',
              cursor: 'pointer',
              background: 'transparent',
              color: pagecheck == 1 ? 'grey' : 'black',
              boxShadow: 'none',
              width: '50px',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Tooltip title={'Previous'} style={{

              }}>
                <Icon icon="fa:angle-left" width={10} />
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={2} onClick={nextBtn} style={{
              width: 'max-content',
              background: 'transparent',
              cursor: 'pointer',
              color: 'black',
              boxShadow: 'none',
              width: '50px',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Tooltip title={'Next'} style={{

              }}>
                <Icon icon="fa:angle-right" width={10} />
              </Tooltip>

            </Grid>
          </div> : null}
      </>
    </>
  );
}
