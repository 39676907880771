import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./index.css";
import Autocomplete from "react-google-autocomplete";

export default function PlacePickerComponent(props) {
  const { onAddressChange, formError, value } = props;

  const [searchValue, setSearchValue] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [address, setAddress] = useState({});

  const key = "AIzaSyAIsIEaD-eDbRnj-T8MmPsZk7aOOwyWjtY";

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  useEffect(() => {
    let coordinates = [lng, lat];
    setAddress((address) => ({
      ...address,
      coordinates: coordinates,
    }));
  }, [lng]);

  useEffect(() => {
    let coordinates = [lng, lat];
    setAddress((address) => ({
      ...address,
      coordinates: coordinates,
    }));
  }, [lat]);

  useEffect(() => {
    onAddressChange(address);
  }, [address]);

  const getPlaceDetail = (place) => {
    const addressComponents = place?.address_components;
    console.log("place == ", place?.address_components);
    let region, city, country;
    addressComponents.forEach(function (component) {
      if (component.types.includes("administrative_area_level_1")) {
        region = component.long_name;
      }
      if (component.types.includes("locality")) {
        city = component.long_name;
      }
      if (component.types.includes("country")) {
        country = component.long_name;
      }
    });
    setLat(place.geometry.location.lat);
    setLng(place.geometry.location.lng);
    setAddress((address) => ({
      ...address,
      address: place.formatted_address,
      region: region,
      country: country,
      postalCode: "",
      city: city,
    }));
  };

  const options = {
    types: ["address"],
    componentRestrictions: { country: "ca" },
  };

  return (
    <>
      <div
        style={{
          border: "0.5px solid #000",
          borderRadius: "5px",
          padding: "8px",
        }}
      >
        <Autocomplete
          placeholder="Address"
          fetchDetails={true}
          value={searchValue}
          apiKey={key}
          options={options}
          onPlaceSelected={getPlaceDetail}
          onChange={(val) => setSearchValue(val.target.value)}
        />
      </div>
      <Typography sx={{ color: "red", fontSize: 12, ml: 1 }}>
        {formError ? formError : ""}
      </Typography>
    </>
  );
}
