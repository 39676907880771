import React from "react";
import { Box, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Icon } from "@iconify/react";
import AdminButton from "../../components/adminButton";
import AdminInput from "../../components/adminInput";
import instance from "../../services"
import { SKILIIAPI } from "../../services/apiConstant";
import { toast } from "react-toastify";






export default function ChangePassword() {
  const navigate = useNavigate();
  const [formError, setFormError] = useState({});
  // const { id } = useParams();
  const [showOldPassword, setShowOldPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");






  const handleSubmitChangePassword = (event) => {
    event.preventDefault();
    if (!oldPassword) {
      setFormError((formError) => ({
        ...formError,
        oldPassword: "Please enter a old password"
      }));
      return;
    }
    if (!newPassword) {
      setFormError((formError) => ({
        ...formError,
        newPassword: "Please enter a new password"
      }));
      return;
    }
    var data = {
      oldPassword: oldPassword,
      newPassword: newPassword
    };
    instance
      .post(`${SKILIIAPI.categories.changePassword}`, data, {
        headers: {
          "Content-Type": " application/json"
        }
      })
      .then((response) => {
        toast.success(response.data.message);
        let result = response.data;
        if (result.status) {
        //   setInputData((inputData) => ({
        //     ...inputData,
        //     categoryImage: ""
        //   }))

          // if (filetype === "category") {
          //   setSrc("");
          // }
        }
      })
      .catch(() => {
        ("error");
      });  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
    
      
        <Grid container>
            <Grid
              container
              sx={{ border: " 1px solid rgba(224, 224, 224, 1)", p: 2, borderRadius: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      mb: -1,
                      color: "#212b36",
                      fontWeight: 500,
                      fontSize: 14,
                      display: "block"
                    }}>
                        Old Password
                  </Typography>
                  <AdminInput
                    type={showOldPassword ? "text" : "password"}
                    value={oldPassword}
                    onChange={(val) => {
                      if (val) {
                        setOldPassword(val?.target?.value);
                        setFormError((formError) => ({
                          ...formError,
                          oldPassword: ""
                        }));
                      }
                    }}
                    formError={formError.oldPassword}
                    endIcon={
                      showOldPassword ? (
                        <Icon icon="tabler:eye" width="28" height="28" color="black" />
                      ) : (
                        <Icon icon="tabler:eye-off" width="28" height="28" color="black" />
                      )
                    }
                    PasswordShowClick={() => setShowOldPassword(!showOldPassword)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      mb: -1,
                      color: "#212b36",
                      fontWeight: 500,
                      fontSize: 14,
                      display: "block"
                    }}>
                   New Password
                  </Typography>
                  <AdminInput
                    type={showNewPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={(val) => {
                      if (val) {
                        setNewPassword(val?.target?.value);
                        setFormError((formError) => ({
                          ...formError,
                          newPassword: ""
                        }));
                      }
                    }}
                    formError={formError.newPassword}
                    endIcon={
                      showNewPassword ? (
                        <Icon icon="tabler:eye" width="28" height="28" color="black" />
                      ) : (
                        <Icon icon="tabler:eye-off" width="28" height="28" color="black" />
                      )
                    }
                    PasswordShowClick={() => setShowNewPassword(!showNewPassword)}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 1 }}>
                <Grid xs={2}>
                  <AdminButton
                    title={"Save"}
                    onClick={(event) => {
                      handleSubmitChangePassword(event);
                    }}
                    sx={{ width: 150 }}
                  />
                </Grid>
                <Grid xs={2}>
                  <AdminButton
                    sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" }, width: 150 }}
                    title={"Cancel"}
                    onClick={() => {
                      navigate("/categories");
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      </Box>
    </>
  );
}
