import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";
import { DialogTitle } from "@mui/material";
import AdminButton from "../../adminButton";
import AdminSelect from "../../adminSelect";
import AdminInput from "../../adminInput";
import AdminRadio from "../../adminRadio";
import { IconButton } from "@mui/material";
import { Icon } from "@mui/material";

export default function UserFilterDialog(props) {
  const { open, handleClose, handleSave } = props;
  const [userFilter, setUserFilter] = useState({});

  const userTypeData = [
    {
      label: "Buyer",
      id: 2,
    },
    {
      label: "Seller",
      id: 3,
    },
  ];

  const userStatus = [
    {
      label: "Active",
      id: 1,
    },
    {
      label: "Deactive",
      id: 0,
    },
  ];

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>User Filter</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000",
          }}
        >
          <Icon icon="ic:baseline-close" />
        </IconButton>
        <DialogContent>
          <Box>
            <AdminRadio
              title={"User Type"}
              radioBtnData={userTypeData}
              value={userFilter.userType}
              handleChange={(val) => {
                setUserFilter((userFilter) => ({
                  ...userFilter,
                  userType: val,
                }));
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <AdminRadio
              title={"User Status"}
              radioBtnData={userStatus}
              value={userFilter.userStatus}
              handleChange={(val) => {
                setUserFilter((userFilter) => ({
                  ...userFilter,
                  userStatus: val,
                }));
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <AdminButton
            title={"Clear Filter"}
            onClick={() => {
              userFilter.userType = null;
              userFilter.userStatus = null;
              handleSave(userFilter);
              handleClose();
            }}
          />
          <AdminButton
            title={"Save"}
            onClick={() => {
              handleSave(userFilter);
              handleClose();
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
