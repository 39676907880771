import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
// import { FaCircle } from "react-icons/fa";
// import { textColor } from "../../theme/style";

export default function PrivacyPolicy() {
  const [type] = React.useState("disc");
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box
          sx={{
            border: "2px solid #EAEAEA",
            borderRadius: "0.5%",
            padding: "1em",
          }}
        >
          <Typography variant="h5" gutterBottom margin={"2%"} align="center">
            Privacy Policy
          </Typography>

          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            This privacy policy applies to the Skilii app (hereby referred to as
            "Application") for mobile devices that was created by Skilii (hereby
            referred to as "Service Provider") as a Free service. This service
            is intended for use "AS IS".
          </Typography>
          <Typography
            sx={{
              ml: 1.5,
              mb: 1,
            }}
            variant="h6"
            component="div"
          >
            Information Collection and Use
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            The Application collects information when you download and use it.
            This information may include information such as
            <List marker={type}>
              <ListItem>
                Your device's Internet Protocol address (e.g. IP address)
              </ListItem>
              <ListItem>
                The pages of the Application that you visit, the time and date
                of your visit, the time spent on those pages
              </ListItem>
              <ListItem>The time spent on the Application</ListItem>
              <ListItem>
                The operating system you use on your mobile device
              </ListItem>
            </List>
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            The Application does not gather precise information about the
            location of your mobile device.
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            The Service Provider may use the information you provided to contact
            you from time to time to provide you with important information,
            required notices and marketing promotions.
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            For a better experience, while using the Application, the Service
            Provider may require you to provide us with certain personally
            identifiable information. The information that the Service Provider
            request will be retained by them and used as described in this
            privacy policy.
          </Typography>
          <Typography
            sx={{
              ml: 1.5,
              mb: 1,
            }}
            variant="h6"
            component="div"
          >
            Third Party Access
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            Only aggregated, anonymized data is periodically transmitted to
            external services to aid the Service Provider in improving the
            Application and their service. The Service Provider may share your
            information with third parties in the ways that are described in
            this privacy statement.
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            Please note that the Application utilizes third-party services that
            have their own Privacy Policy about handling data. Below are the
            links to the Privacy Policy of the third-party service providers
            used by the Application:
            <List marker={type}>
              <ListItem>
                <Link
                  to="https://policies.google.com/terms"
                  style={{ textDecoration: "none", color: "grey" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseOver={(e) => (e.target.style.color = "black")}
                  onMouseOut={(e) => (e.target.style.color = "grey")}
                >
                  Google Play Services
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  to="https://www.google.com/analytics/terms/"
                  style={{ textDecoration: "none", color: "grey" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseOver={(e) => (e.target.style.color = "black")}
                  onMouseOut={(e) => (e.target.style.color = "grey")}
                >
                  Google Analytics for Firebase
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  to="https://firebase.google.com/terms/crashlytics"
                  style={{ textDecoration: "none", color: "grey" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseOver={(e) => (e.target.style.color = "black")}
                  onMouseOut={(e) => (e.target.style.color = "grey")}
                >
                  Firebase Crashlytics
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  to="https://www.facebook.com/legal/terms/plain_text_terms"
                  style={{ textDecoration: "none", color: "grey" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseOver={(e) => (e.target.style.color = "black")}
                  onMouseOut={(e) => (e.target.style.color = "grey")}
                >
                  Facebook
                </Link>
              </ListItem>
            </List>
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            The Service Provider may disclose User Provided and Automatically
            Collected Information:
            <List marker={type}>
              <ListItem color="text.secondary">
                as required by law, such as to comply with a subpoena, or
                similar legal process;
              </ListItem>
              <ListItem color="text.secondary">
                when they believe in good faith that disclosure is necessary to
                protect their rights, protect your safety or the safety of
                others, investigate fraud, or respond to a government request;
              </ListItem>
              <ListItem color="text.secondary">
                with their trusted services providers who work on their behalf,
                do not have an independent use of the information we disclose to
                them, and have agreed to adhere to the rules set forth in this
                privacy statement.
              </ListItem>
            </List>
          </Typography>
          <Typography
            sx={{
              ml: 1.5,
              mb: 1,
            }}
            variant="h6"
            component="div"
          >
            Opt-Out Rights
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            You can stop all collection of information by the Application easily
            by uninstalling it. You may use the standard uninstall processes as
            may be available as part of your mobile device or via the mobile
            application marketplace or network.
          </Typography>
          <Typography
            sx={{
              ml: 1.5,
              mb: 1,
            }}
            variant="h6"
            component="div"
          >
            Data Retention Policy
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            The Service Provider will retain User Provided data for as long as
            you use the Application and for a reasonable time thereafter. If
            you'd like them to delete User Provided Data that you have provided
            via the Application, please contact them at support@skilii.com and
            they will respond in a reasonable time.
          </Typography>
          <Typography
            sx={{
              ml: 1.5,
              mb: 1,
            }}
            variant="h6"
            component="div"
          >
            Children
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            The Service Provider does not use the Application to knowingly
            solicit data from or market to children under the age of 13. The
            Application does not address anyone under the age of 13.
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            The Service Provider does not knowingly collect personally
            identifiable information from children under 13 years of age. In the
            case the Service Provider discover that a child under 13 has
            provided personal information, the Service Provider will immediately
            delete this from their servers. If you are a parent or guardian and
            you are aware that your child has provided us with personal
            information, please contact the Service Provider
            (support@skilii.com) so that they will be able to take the necessary
            actions.
          </Typography>
          <Typography
            sx={{
              ml: 1.5,
              mb: 1,
            }}
            variant="h6"
            component="div"
          >
            Security
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            The Service Provider is concerned about safeguarding the
            confidentiality of your information. The Service Provider provides
            physical, electronic, and procedural safeguards to protect
            information the Service Provider processes and maintains.
          </Typography>
          <Typography
            sx={{
              ml: 1.5,
              mb: 1,
            }}
            variant="h6"
            component="div"
          >
            Changes
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            This Privacy Policy may be updated from time to time for any reason.
            The Service Provider will notify you of any changes to the Privacy
            Policy by updating this page with the new Privacy Policy. You are
            advised to consult this Privacy Policy regularly for any changes, as
            continued use is deemed approval of all changes.
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            This privacy policy is effective as of 2024-04-29
          </Typography>
          <Typography
            sx={{
              ml: 1.5,
              mb: 1,
            }}
            variant="h6"
            component="div"
          >
            Your Consent
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            By using the Application, you are consenting to the processing of
            your information as set forth in this Privacy Policy now and as
            amended by us.
          </Typography>
          <Typography
            sx={{
              ml: 1.5,
              mb: 1,
            }}
            variant="h6"
            component="div"
          >
            Contact Us
          </Typography>
          <Typography sx={{ mb: 1.5, ml: 1.5 }} color="text.secondary">
            If you have any questions regarding privacy while using the
            Application, or have questions about the practices, please contact
            the Service Provider via email at support@skilii.com.
          </Typography>
        </Box>
      </Container>
    </React.Fragment>
  );
}
