import { createSlice } from "@reduxjs/toolkit";
import api from "../../services";
import { API_RESPONSE, SKILIIAPI, SERVER_URL } from "../../services/apiConstant";
import { toast } from "react-toastify";
import axios from "axios";
import instance from "../../services";

const slice = createSlice({
  name: "categories",
  initialState: {
    categorieslistData: [],
    categoriesData: null,
    totalCategoryCount: null,
    categoriesImage: null,
    message: "",
    isLoading: false,
    userslistData: [],
    totalUsersCount: null,
    connectedAccountsListData: [],
    totalConnectedAccountsCount: null,
    serviceListData: [],
    serviceListCount: null,
    dashBoardAdminData: null,
    selectCategoryData: null,
    bookingData: [],
    bookingListCount: null,
    transactionsData: [],
    transactionsCount: null,
    serviceProviders: [],
    serviceData: null,
    appData: null,
    userData: null,
    servicePhotos: [],
    servicePhotosUrl: [],
    userAccountDelation: null,
  },
  reducers: {
    apiFetchingStart: (state) => {
      state.isLoading = true;
    },
    apiFetchingEnd: (state) => {
      state.isLoading = false;
    },
    categorieslistSuccess(state, action) {
      state.categorieslistData = action.payload.data;
      state.totalCategoryCount = action.payload.totalCount;
      state.isLoading = false;
    },
    categoryDetailsSuccess(state, action) {
      state.categoriesData = action.payload;
    },
    deletesSuccess(state, action) {
      let type = action.payload.type;
      let id = action.payload.id;
      if (type === 1) {
        state.categorieslistData = state.categorieslistData.filter(
          (item) => item.categoryUid !== id
        );
      }
    },
    selectCategoriesData(state) {
      state.categoriesData = null;
      state.userData = null;
      state.serviceData = null;
      state.servicePhotos = [];
      state.servicePhotosUrl = [];
    },
    categoryImageSuccess(state, action) {
      state.categoriesImage = action.payload[0];
      state.isLoading = false;
    },
    clearImageDataSuccess(state) {
      state.categoriesImage = null;
    },
    connectedAccountsListSuccess(state, action) {

      console.log('action.payload.data----', action.payload);

      state.connectedAccountsListData = action.payload.data.data;
      state.totalConnectedAccountsCount = action.payload.totalCount;
      state.isLoading = false;
    },
    usersListSuccess(state, action) {
      state.userslistData = action.payload.data;
      state.totalUsersCount = action.payload.totalCount;
      state.isLoading = false;
    },
    userSuspendSuccess(state, action) {
      const newObjId = action.payload.userUid;
      const suspendIndex = state.userslistData?.findIndex(
        (item) => item.userUid === newObjId
      );
      let currentUser = action.payload;
      state.userslistData[suspendIndex] = currentUser;
    },
    deleteStripeSuccess(state, action) {
      let id = action.payload;
      state.connectedAccountsListData = state.connectedAccountsListData.filter(
        (item) => item.id !== id
      );
    },
    deleteUserSuccess(state, action) {
      let id = action.payload;
      state.userslistData = state.userslistData.filter(
        (item) => item.userUid !== id
      );
    },
    serviceListSuccess(state, action) {
      state.serviceListData = action.payload.data;
      state.serviceListCount = action.payload.totalCount;
      state.isLoading = false;
    },
    deletesServiceSuccess(state, action) {
      let id = action.payload;
      state.serviceListData = state.serviceListData.filter(
        (item) => item.serviceUid !== id
      );
    },
    dashBoardAdminSuccess(state, action) {
      state.dashBoardAdminData = action.payload;
      state.isLoading = false;
    },
    selectCategorySuccess(state, action) {
      state.selectCategoryData = action.payload;
    },
    serviceBookingSuccess(state, action) {
      state.bookingData = action.payload.data;
      state.bookingListCount = action.payload.totalCount;
      state.isLoading = false;
    },
    serviceTransactionsSuccess(state, action) {
      state.transactionsData = action.payload.data;
      state.transactionsCount = action.payload.total;
      state.isLoading = false;
    },
    serviceProvidersSuccess(state, action) {
      state.serviceProviders = action.payload;
    },
    serviceDataSuccess(state, action) {
      state.serviceData = action.payload;
    },
    userDetailsSuccess(state, action) {
      state.userData = action.payload;
    },
    appDetailsSuccess(state, action) {
      state.appData = action.payload;
    },
    serviceImagedataSuccess(state, action) {
      state.servicePhotos = state.servicePhotos.concat(
        action.payload.servicePhotos
      );
      state.servicePhotosUrl = state.servicePhotosUrl.concat(
        action.payload.servicePhotosUrl
      );
    },
    serviceImageRemoveSuccess(state, action) {
      let data = [...state.servicePhotos];
      let urlData = [...state.servicePhotosUrl];
      data.splice(action.payload, 1);
      urlData.splice(action.payload, 1);
      state.servicePhotos = data;
      state.servicePhotosUrl = urlData;
    },
    setServiceImageSuccess(state, action) {
      state.servicePhotos = action.payload.servicePhotosUrl;
      state.servicePhotosUrl = action.payload.servicePhotos;
    },
    userAccountDelationSuccess(state, action) {
      console.log(action, "action");
      state.userAccountDelation = action.payload;
    },
  },
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

const {
  categorieslistSuccess,
  categoryDetailsSuccess,
  deletesSuccess,
  selectCategoriesData,
  clearImageDataSuccess,
  usersListSuccess,
  connectedAccountsListSuccess,
  userSuspendSuccess,
  serviceListSuccess,
  deletesServiceSuccess,
  deleteUserSuccess,
  deleteStripeSuccess,
  dashBoardAdminSuccess,
  selectCategorySuccess,
  apiFetchingStart,
  apiFetchingEnd,
  serviceBookingSuccess,
  serviceTransactionsSuccess,
  serviceProvidersSuccess,
  serviceDataSuccess,
  userDetailsSuccess,
  appDetailsSuccess,
  serviceImagedataSuccess,
  serviceImageRemoveSuccess,
  setServiceImageSuccess,
  userAccountDelationSuccess,
} = slice.actions;

// const apiurl = SKILIIAPI.categories;

export const categorieslist = (data) => async (dispatch) => {
  axios
    .post(
      `${SERVER_URL}${SKILIIAPI.categories.list}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AUTH_API_KEY}`,
        },
      }
    )
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(categorieslistSuccess(result));
      } else {
        dispatch(categorieslistSuccess(result));
      }
    })
    .catch(() => { });
};

export const addCategory = (requestParams, navigate) => async () => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.categories.addCategory}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        navigate("/", true);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const categoryDetails = (id) => async (dispatch) => {
  axios
    .get(
      `${SERVER_URL}${SKILIIAPI.categories.categoryDetails
      }` + id,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AUTH_API_KEY}`,
        },
      }
    )
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(categoryDetailsSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const updateCategory = (requestParams, navigate) => async () => {
  api
    .put(`${SERVER_URL}${SKILIIAPI.categories.updateCategory}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        navigate("/", true);
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const deletes =
  (requestParams, fetchCategoryList, data) => async (dispatch) => {
    api
      .post(`${SERVER_URL}${SKILIIAPI.categories.deletes}`, requestParams)
      .then((response) => {
        let result = response.data;
        if (result.status === API_RESPONSE.SUCCESS) {
          fetchCategoryList(
            data.page,
            data.perPageRows,
            data.sortColumn,
            data.sortOrder,
            data.searchValue
          );
          dispatch(deletesSuccess(requestParams));
        } else {
          toast.error(result.message);
        }
      })
      .catch(() => { });
  };

export const clearStateData = () => async (dispatch) => {
  dispatch(selectCategoriesData());
};

export const clearImageData = () => async (dispatch) => {
  dispatch(clearImageDataSuccess());
};

export const getStripeAccounts = (requestParams) => async (dispatch) => {
  dispatch(apiFetchingStart());
  api
    .post(`${SERVER_URL}${SKILIIAPI.users.connectedAccounts}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {

        console.log('requestParams=======', requestParams);

        const apiResponse = result.data;

        console.log('apiResponse=======', result);
        // const startIndex = (requestParams.page - 1) * 5;
        // const endIndex = startIndex + requestParams.perPage;

        // const paginatedData = apiResponse.slice(startIndex, endIndex);

        // const flattenedData = paginatedData.map(account => ({
        //   ...account,
        //   mcc: account.business_profile.mcc,
        //   name: account.individual ? account.individual.first_name + ' ' + account.individual.last_name : account.email
        // }));

        const json = { data: apiResponse, totalCount: 1000 }

        dispatch(connectedAccountsListSuccess(json));
      } else {
        toast.error(result.message);
      }
    })
    .catch((err) => {

      console.log('flattenedData=err===', err);

    });
};

export const getAllUsersList = (requestParams) => async (dispatch) => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.users.list}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(usersListSuccess(result));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const deleteStripeAccount =
  (requestParams, fetchCategoryList, data) => async (dispatch) => {
    console.log('requestParams---', requestParams);

    api
      .post(`${SERVER_URL}${SKILIIAPI.users.deleteStripeAccount}`, { stripeAccountId: requestParams })
      .then((response) => {
        let result = response.data;
        if (result.status === API_RESPONSE.SUCCESS) {
          getStripeAccounts(
            data.page,
            data.limit,
          );
          toast.success(result.message);
          dispatch(deleteStripeSuccess(requestParams));
        } else {
          toast.error(result.message);
        }
      })
      .catch(() => { });
  };

export const deleteUserAccount =
  (requestParams, fetchCategoryList, data) => async (dispatch) => {
    api
      .delete(`${SERVER_URL}${SKILIIAPI.users.deleteUserAccount}` + requestParams)
      .then((response) => {
        let result = response.data;
        if (result.status === API_RESPONSE.SUCCESS) {
          fetchCategoryList(
            data.page,
            data.perPageRows,
            data.sortColumn,
            data.sortOrder,
            data.searchValue
          );
          toast.success(result.message);
          dispatch(deleteUserSuccess(requestParams));
        } else {
          toast.error(result.message);
        }
      })
      .catch(() => { });
  };

export const userAccountSuspend = (requestParams) => async (dispatch) => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.users.userAccountSuspend}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(userSuspendSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const getServiceList = (requestParams) => async (dispatch) => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.service.list}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(serviceListSuccess(result));
      } else {
        dispatch(serviceListSuccess(result));
      }
    })
    .catch(() => { });
};

export const deleteService =
  (requestParams, fetchCategoryList, data) => async (dispatch) => {
    api
      .delete(`${SERVER_URL}${SKILIIAPI.service.deleteService}` + requestParams)
      .then((response) => {
        let result = response.data;
        if (result.status === API_RESPONSE.SUCCESS) {
          toast.success(result.message);
          fetchCategoryList(
            data.page,
            data.perPageRows,
            data.sortColumn,
            data.sortOrder,
            data.searchValue
          );
          dispatch(deletesServiceSuccess(requestParams));
        } else {
          toast.error(result.message);
        }
      })
      .catch(() => { });
  };

export const dashBoardForAdmin = () => async (dispatch) => {

  console.log(process.env.REACT_APP_AUTH_API_KEY);

  dispatch(apiFetchingStart());
  api
    .get(`${SERVER_URL}${SKILIIAPI.dashBoardForAdmin}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_API_KEY}`,
      },
    })
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(dashBoardAdminSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const selectCategory = () => async (dispatch) => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.service.selectCategory}`)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(selectCategorySuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const loadingStart = () => async (dispatch) => {
  dispatch(apiFetchingStart());
};

export const loadingEnd = () => async (dispatch) => {
  dispatch(apiFetchingEnd());
};

export const getBookingList = (requestParams) => async (dispatch) => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.booking.list}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(serviceBookingSuccess(result));
      } else {
        dispatch(serviceBookingSuccess(result));
      }
    })
    .catch(() => { });
};

export const getTransactionsList = (requestParams) => async (dispatch) => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.transactions.list}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(serviceTransactionsSuccess(result));
      } else {
        dispatch(serviceTransactionsSuccess(result));
      }
    })
    .catch(() => { });
};

export const searchServiceProvider = (requestParams) => async (dispatch) => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.service.serviceProvider}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(serviceProvidersSuccess(result.data));
      } else {
        // dispatch(serviceTransactionsSuccess(result));
      }
    })
    .catch(() => { });
};

export const createService = (requestParams, navigate) => async (dispatch) => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.service.createService}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(clearImageDataSuccess());
        dispatch(selectCategoriesData());
        toast.success("Service added successfully.");
        navigate("/dashboard/services");
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const updateService = (requestParams, navigate) => async (dispatch) => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.service.updateService}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(clearImageDataSuccess());
        dispatch(selectCategoriesData());
        toast.success("Service updated successfully.");
        navigate("/dashboard/services");
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const serviceDetails = (requestParams, navigate) => async (dispatch) => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.service.getServiceDetail}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(serviceDataSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const updateAppConstant = (requestParams, navigate) => async () => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.users.updateFees}`, requestParams)
    .then((response) => {
      let result = response.data;

      if (result.status === API_RESPONSE.SUCCESS) {
        toast.success('Fees Updated!');
        // navigate("/dashboard/Users");
      } else {
        toast.error(result.message);
      }
    })
    .catch((errr) => { 
      console.log('errr', errr);
    });
};

export const addUser = (requestParams, navigate) => async () => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.users.addUser}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        toast.success(result.message);
        navigate("/dashboard/Users");
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const appDetails = (requestParams) => async (dispatch) => {
  api
    .post(`${SERVER_URL}${SKILIIAPI.users.updateFees}`)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(appDetailsSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const userDetails = (requestParams) => async (dispatch) => {
  api
    .get(`${SERVER_URL}${SKILIIAPI.users.userDetails}?userUid=${requestParams}`)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(userDetailsSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const updateUser = (requestParams, navigate) => async () => {
  api
    .put(`${SERVER_URL}${SKILIIAPI.users.updateUser}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        toast.success(result.message);
        navigate("/dashboard/Users");
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const serviceImageUpload = (formData, status) => async (dispatch) => {
  instance
    .post(`${SERVER_URL}${SKILIIAPI.service.serviceImagesUpload}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      let result = response.data.data[0];
      if (response.data.status === 1) {
        const imageData = {
          servicePhotos: result?.fileName,
          servicePhotosUrl: result?.path,
        };
        dispatch(serviceImagedataSuccess(imageData));
        if (status) {
          dispatch(loadingEnd());
          toast.success(response.data.message);
        }
      } else {
        toast.error(response.data.message);
      }
    })
    .catch(() => {
      ("error");
      dispatch(loadingEnd());
    });
};
export const userAccountdelition = (requestParams, navigate) => async (dispatch) => {
  console.log("re", requestParams);
  api
    .post(SKILIIAPI.users.userAccountDelation, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === API_RESPONSE.SUCCESS) {
        dispatch(userAccountDelationSuccess(requestParams));
        navigate("/otp", {
          state: requestParams
        });
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => { });
};

export const serviceImageRemove = (index, status) => async (dispatch) => {
  dispatch(serviceImageRemoveSuccess(index));
};

export const setServiceImage = (index, status) => async (dispatch) => {
  dispatch(setServiceImageSuccess(index));
};
