import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import { DialogTitle } from "@mui/material";
import AdminButton from "../../adminButton";
import AdminSelect from "../../adminSelect";
import AdminInput from "../../adminInput";

export default function ConfirmDialog(props) {
    const { title, content, open, handleClose, handleSubmit } = props;
    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <p>
                        {content}
                    </p>
                </DialogContent>
                <DialogActions>
                    <AdminButton title={"No"} onClick={handleClose} />
                    <AdminButton title={"Yes"} onClick={handleSubmit} />
                </DialogActions>
            </Dialog>
        </>
    );
}
