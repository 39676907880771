import React, { useEffect, useState } from "react";
import InputForm from "../../components/inputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  appDetails,
  updateAppConstant,
  clearStateData,
  updateUser,
} from "../../store/slice/categories";
import { useNavigate, useParams } from "react-router-dom";

export default function AppConstant(props) {
  const [inputData, setInputData] = useState({});
  const [phoneData, setPhoneData] = useState({});
  const { appData, isLoading } = useSelector((state) => state.categories);

  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const { id } = useParams();
  const [inputError, setInputError] = useState({});

  useEffect(() => {
    dispatch(clearStateData());
  }, []);

  useEffect(() => {
      dispatch(appDetails());
  }, []);

  useEffect(() => {
    if (appData) {
      setInputData((inputData) => ({
        ...inputData,
        ...appData,
        Fees: appData.fees,
      }));
    }
  }, [appData]);

  const addInput = [
    {
      title: "Fees (%)",
      inputType: "AdminInput",
      isRequired: true,
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          Fees: val,
        }));
        if (val > 100) {
          setInputError((inputError) => ({
            ...inputError,
            Fees: "Please add lase then 100.",
          }));
        }else{
          setInputError((inputError) => ({
            ...inputError,
            Fees: " ",
          }));

        }
      },
      colNum: 6,
      type:'number',
      value: inputData?.Fees,
      formError: inputError.Fees,
    }
  ];

  const removeProfile = () => {
    removeFileUpload();
  };

  const removeFileUpload = () => {
    setInputData((inputData) => ({
      ...inputData,
      categoryImage: "",
      fileName: "",
    }));
  };

  const handleSubmit = () => {

    if(inputData.Fees > 100){
      
    }else{
      if (!inputData.Fees) {
        setInputError((inputError) => ({
          ...inputError,
          Fees: "Please enter fees",
        }));
        return;
      }
  
      const inpData = {
        fees: inputData.Fees,
        isEdit: true
      }
      console.log('inputData---', inpData);
      dispatch(updateAppConstant(inpData, navigate));

    }
  };

  const handleBack = () => {
    navigate("/dashboard/app");
    dispatch(clearStateData());
  };

  return (
    <InputForm
      formTitle={"Update fees"}
      removeProfile={removeProfile}
      inputData={addInput}
      btnSave={handleSubmit}
      isLoading={isLoading}
      btnBack={handleBack}
    />
  );
}
