import React, { useState } from "react";
import {
  Box,
  Card,
  Container,
  FormControl,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import AdminButton from "../../components/adminButton";
import AdminInput from "../../components/adminInput";
import { useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { userAccountdelition } from "../../store/slice/categories";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo.png";

export default function AccountDeletion() {
  const dispatch = useDispatch();
  const [field, setField] = useState({ value: "Email", additionalData: {} }); // Initializing field state with an object containing the value "Email"
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setField({ value: selectedValue, additionalData: {} }); // Update field state when the input value changes
  
    // Clear email field when switching to Phone Number
    if (selectedValue === "Phone-Number") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        email: "",
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        phoneNumber: "",
      }));
    }
  };
  
  const handleSubmit = () => {
    const validEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );

    if (!formData?.email) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter your email address",
      }));
      return;
    } else if (!formData?.email.includes("@")) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    } else if (!formData?.email.includes(".")) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    } 
     else if (!validEmail.test(formData?.email)) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    }

    const data = {
      email: formData?.email,
      phoneNumber: formData?.phoneNumber,
      type: 1,
      verificationCode: "",
    };

    dispatch(userAccountdelition(data, navigate));
    setFormData({});
   
  };

  console.log("formData", formData);
  return (
    <div
      style={{
        background: "black",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container maxWidth="sm">
        <Card sx={{ p: 5 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid container display={"flex"} justifyContent={"center"}>
              <Box sx={{ mx: "auto", width: 150 }}>
                <img
                  src={Logo}
                  alt="logo"
                  style={{ width: "100%", borderRadius: "50%" }}
                />
              </Box>
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                Request For Account Deletion
              </Typography>
            </Grid>
          </Stack>

          <Typography sx={{ fontSize: "1.2rem", my: 1 }}>
            Send a request to delete your account and personally identifiable
            information that is stored on our system.
          </Typography>

          <Box>
            <FormControl fullWidth>
              <Select
                SelectDisplayProps={{
                  style: { paddingTop: 10, paddingBottom: 10 },
                }}
                value={field.value}
                onChange={handleChange}
                aria-label="Without label"                
                
              >
                <MenuItem   value={"Email"}>Email</MenuItem>
                <MenuItem value={"Phone-Number"}>Phone Number</MenuItem>
              </Select>
            </FormControl>
            {field.value === "Email" ? (
              <AdminInput
                title="Email"
                sx={{ height: 100 }}
                value={formData?.email}
                onChange={(val) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    email: val,
                  }));
                  if (val) {
                    setFormError((prevFormError) => ({
                      ...prevFormError,
                      email: "",
                    }));
                  }
                }}
                formError={formError?.email}
              />
            ) : (
              <AdminInput
                title="Phone Number"
                sx={{ height: 100 }}
                value={formData?.phoneNumber}
                onChange={(val) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    phoneNumber: val,
                  }));
                  if (val) {
                    setFormError((prevFormError) => ({
                      ...prevFormError,
                      phoneNumber: "",
                    }));
                  }
                }}
                formError={formError?.phoneNumber}
              />
            )}
            <Box>
              <AdminButton
                fullWidth
                title="Submit"
                onClick={handleSubmit}
                sx={{
                  fontWeight: 600,
                  fontSize: "1.2rem",
                  py: 3.5,
                  borderRadius: 3,
                  ":hover": {
                    color: "white",
                    backgroundColor: "black",
                  },
                }}
              />
            </Box>
          </Box>
        </Card>
      </Container>
    </div>
  );
}
