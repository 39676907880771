import React from "react";
import { Box, Grid } from "@mui/material";
import "./style.css";
import Spinner from "../spinner";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import uploadImage from "../../assets/img/uploadImage.png";

export default function ImageUpload(props) {
  const { onChange, uploadedImage, isLoading, removeProfile, multiple } = props;

  const onChangeFile = (event) => {
    if (multiple) {
      const count = uploadedImage
        ? uploadedImage?.length + event.target.files.length
        : event.target.files.length;
      if (count < 11) {
        for (let i = 0; i < event.target.files.length; i++) {
          const file = event.target.files[i];
          if (file) {
            const formData = new FormData();
            formData.append("file", file);
            onChange(formData, event.target.files.length === i + 1);
          }
        }
      } else {
        toast.error("You can not add more then 10 images");
      }
    } else {
      const file = event.target.files[0];
      const formData = new FormData();
      if (file) {
        formData.append("file", file);
      }
      onChange(formData);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={2} sx={{ position: "relative" }}>
          {!multiple && uploadedImage && (
            <>
              <Icon
                icon="material-symbols:cancel"
                color="red"
                onClick={removeProfile}
                style={{
                  position: "absolute",
                    right: 0,
                    color: "red",
                    fontSize: 30,
                    marginTop: -10,
                    marginRight: -10,
                    cursor: "pointer",
                    zIndex: 25
                }}
              />
            </>
          )}
          <input
            type="file"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
            multiple={multiple}
            className="imageUpload"
            accept="image/png, image/jpg, image/jpeg"
            onChange={onChangeFile}
          />
          <img
            src={!multiple && uploadedImage ? uploadedImage : uploadImage}
            sx={{ objectFit: "cover" }}
            alt="logo"
            width={"100%"}
            height={"200px"}
          />
          {isLoading ? (
            <>
              <Box
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "40%",
                }}
              >
                <Spinner />
              </Box>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {multiple && uploadedImage && uploadedImage.length > 0
          ? uploadedImage.map((item, i) => (
              <Grid item md={2} sx={{ position: "relative" }} key={i}>
                <Icon
                  icon="material-symbols:cancel"
                  color="red"
                  onClick={() => {
                    removeProfile(i);
                  }}
                  style={{
                    position: "absolute",
                    right: 0,
                    color: "red",
                    fontSize: 30,
                    marginTop: -10,
                    marginRight: -10,
                    cursor: "pointer",
                    zIndex: 5,
                  }}
                />
                <img
                  src={item}
                  alt="logo"
                  width={"100%"}
                  height={"150px"}
                  style={{ objectFit: "cover" }}
                />
              </Grid>
            ))
          : ""}
      </Grid>
    </>
  );
}
