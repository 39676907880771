import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import image from "../../assets/img/logo.png";
import { Icon } from "@iconify/react";
import AdminButton from "../../components/adminButton";
import AdminInput from "../../components/adminInput";
import { useState } from "react";
import { color } from "../../theme/color";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPasswordForAdmin } from "../../store/slice/auth";
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const handleSubmit = () => {
    const validEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );

    // if (!email) {
    //   setFormError((formError) => ({
    //     ...formError,
    //     email: "Please Enter Your Email Address",
    //   }));
    //   return;
    // } else if (!email.includes("@")) {
    //   setFormError((formError) => ({
    //     ...formError,
    //     email: "Please enter a valid email address",
    //   }));
    //   return;
    // } else if (!email.includes(".")) {
    //   setFormError((formError) => ({
    //     ...formError,
    //     email: "Please enter a valid email address",
    //   }));
    //   return;
    // } else if (!validEmail.test(email)) {
    //   setFormError((formError) => ({
    //     ...formError,
    //     email: "Please enter a valid email address",
    //   }));
    //   return;
    // }
    if (!password) {
      setFormError((formError) => ({
        ...formError,
        password: "Please enter a password",
      }));
      return;
    }

    const requestParam = {
      secretKey: id,
      newPassword: password,
    };

    dispatch(resetPasswordForAdmin(requestParam, navigate));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        alignItems="center"
        justifyContent="center"
        sx={{
          padding: { xs: false, sm: false, md: 10, lg: 10 },
          flexGrow: 1,
          backgroundColor: color.main,
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CssBaseline />
        <Grid item xs={12} sm={8} md={4} component={Paper}>
          <Box
            sx={{
              my: 8,
              mx: 10,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ mx: "auto", width: 150 }}>
              <img
                src={image}
                alt="logo"
                style={{ width: "100%", borderRadius: "50%" }}
              />
            </Box>
            <Typography
              sx={{ textAlign: "center" }}
              component="h1"
              variant="h5"
            >
              Reset Password
            </Typography>
            <Box noValidate sx={{ mt: 1 }}>
              {/* <AdminInput
                title="Email Address"
                type="email"
                value={email}
                onChange={(val) => {
                  if (val) {
                    setEmail(val);
                    setFormError((formError) => ({
                      ...formError,
                      email: "",
                    }));
                  } else {
                    setEmail("");
                  }
                }}
                formError={formError.email}
              /> */}
              <AdminInput
                title="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(val) => {
                  if (val) {
                    setPassword(val);
                    setFormError((formError) => ({
                      ...formError,
                      password: "",
                    }));
                  } else {
                    setPassword("");
                  }
                }}
                formError={formError.password}
                endIcon={
                  showPassword ? (
                    <Icon
                      icon="tabler:eye"
                      width="25"
                      height="25"
                      color="#9d9ea0"
                    />
                  ) : (
                    <Icon
                      icon="tabler:eye-off"
                      width="25"
                      height="25"
                      color="#9d9ea0"
                    />
                  )
                }
                PasswordShowClick={() => setShowPassword(!showPassword)}
              />
              <AdminButton
                onClick={handleSubmit}
                title={"Submit"}
                fullWidth="true"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
