import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./components/scroll-to-top/scrolltotop";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import { AxiosInterceptor } from "./services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";



function App() {

  const CloseButton = ({ closeToast }) => (
    <Button size="small" onClick={closeToast} style={{ color: "white" }}>
      Ok
    </Button>
  );
  
  return (
  <>  <HelmetProvider>
  <BrowserRouter>
    <AxiosInterceptor>
      <ScrollToTop />
      <Router />
    </AxiosInterceptor>
  </BrowserRouter>
</HelmetProvider>
<ToastContainer
theme="colored"
position={toast.POSITION.TOP_RIGHT}
autoClose={5000}
hideProgressBar={true}
pauseOnHover
draggable
icon={false}
closeButton={CloseButton}
limit={1}
/></>
  );
}

export default App;
