import React, { useEffect, useState } from "react";
import InputForm from "../../components/inputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStateData,
  clearImageData,
  createService,
  loadingStart,
  searchServiceProvider,
  selectCategory,
  serviceDetails,
  updateService,
  serviceImageUpload,
  serviceImageRemove,
  setServiceImage,
} from "../../store/slice/categories";
import { useNavigate, useParams } from "react-router-dom";

export default function AddServices(props) {
  const [inputData, setInputData] = useState();
  const {
    serviceData,
    isLoading,
    serviceProviders,
    selectCategoryData,
    servicePhotos,
    servicePhotosUrl,
  } = useSelector((state) => state.categories);

  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const { id } = useParams();
  const [inputError, setInputError] = useState({});
  const [otherId, setoOtherId] = useState("");

  useEffect(() => {
    if (!id) {
      dispatch(clearStateData());
    }
  }, []);

  useEffect(() => {
    if (id) {
      const data = {
        language: "en",
        serviceUid: id,
        isHistory: false,
        userId: "",
        bookingUid: "",
      };
      dispatch(serviceDetails(data));
    }
  }, [id]);

  useEffect(() => {
    setInputData((inputData) => ({
      ...inputData,
      servicePhotos: servicePhotos,
    }));
  }, [servicePhotos]);
  useEffect(() => {
    setInputData((inputData) => ({
      ...inputData,
      servicePhotosUrl: servicePhotosUrl,
    }));
  }, [servicePhotosUrl]);

  const onChangeFile = (formData, status) => {
    dispatch(loadingStart());
    dispatch(serviceImageUpload(formData, status));
  };

  const removeProfile = (index) => {
    dispatch(serviceImageRemove(index));
  };

  useEffect(() => {
    if (selectCategoryData) {
      const suspendIndex = selectCategoryData?.findIndex(
        (item) => item.categoryName === "Other"
      );
      setoOtherId(selectCategoryData[suspendIndex].categoryUid);
    }
  }, [selectCategoryData]);

  const handleSearch = (val, flag) => {
    const data = {
      search: val,
    };
    if (flag === "Provider") {
      dispatch(searchServiceProvider(data));
    } else {
      dispatch(selectCategory(data));
    }
  };

  useEffect(() => {
    if (serviceData) {
      setInputData((inputData) => ({
        ...inputData,
        ...serviceData,
        sellerId: serviceData.serviceUserId,
      }));
      const data = {
        servicePhotos: serviceData.servicePhotos,
        servicePhotosUrl: serviceData.servicePhotosUrl,
      };
      dispatch(setServiceImage(data));
    }
  }, [serviceData]);

  const addInput = [
    {
      title: "Title",
      inputType: "AdminInput",
      isRequired: true,
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          title: val,
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            title: "",
          }));
        }
      },
      colNum: 6,
      value: inputData?.title,
      formError: inputError.title,
    },
    {
      title: "Category",
      inputType: "AdminAutocomplete",
      isRequired: true,
      handleSearch: (val) => handleSearch(val, "Category"),
      onChange: (val) => {
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            categoryUid: "",
          }));
          setInputData((inputData) => ({
            ...inputData,
            categoryUid: val.id,
            categoryName: "",
          }));
        } else {
          setInputData((inputData) => ({
            ...inputData,
            categoryUid: "",
          }));
        }
      },
      menuData:
        selectCategoryData &&
        selectCategoryData?.map((item) => ({
          label: `${item.categoryName}`,
          id: item.categoryUid,
        })),
      colNum: 6,
      value: inputData?.categoryUid,
      formError: inputError.categoryUid,
    },
    {
      title: "Specify Category",
      inputType: "AdminInput",
      isRequired: true,
      isNotShow: inputData?.categoryUid === otherId ? false : true,
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          categoryName: val,
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            categoryName: "",
          }));
        }
      },
      colNum: 6,
      value: inputData?.categoryName,
      formError: inputError.categoryName,
    },
    {
      title: "Price Type",
      inputType: "AdminSelect",
      isRequired: true,
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          priceType: val,
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            priceType: "",
          }));
        }
      },
      colNum: 6,
      value: inputData?.priceType ? inputData?.priceType : "",
      formError: inputError.priceType,
      menuData: [
        { id: "Project", label: "Project" },
        { id: "Hourly", label: "Hourly" },
      ],
    },
    {
      title: "Price",
      inputType: "AdminInput",
      type: "number",
      // startIcon: "$",
      isRequired: true,
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          price: val,
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            price: "",
          }));
        }
      },
      colNum: 6,
      value: inputData?.price,
      formError: inputError.price,
    },
    {
      title: "Duration",
      inputType: "AdminInput",
      // type: "number",
      isRequired: true,
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          duration: val.replace(/[&/\\#, `!@^_=[|;+()$~%.'":*?<>{}]/g, ""),
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            duration: "",
          }));
        }
      },
      colNum: 6,
      value: inputData?.duration,
      formError: inputError.duration,
    },
    {
      title: "Service Provider",
      inputType: "AdminAutocomplete",
      isRequired: true,
      handleSearch: (val) => handleSearch(val, "Provider"),
      onChange: (val) => {
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            sellerId: "",
          }));
          setInputData((inputData) => ({
            ...inputData,
            sellerId: val.id,
          }));
        } else {
          setInputData((inputData) => ({
            ...inputData,
            sellerId: "",
          }));
        }
      },
      menuData:
        serviceProviders &&
        serviceProviders?.map((item) => ({
          label: `${item.sellerName}`,
          id: item.sellerId,
        })),
      colNum: 6,
      value: inputData?.sellerId,
      formError: inputError.sellerId,
    },
    {
      title: "Address",
      inputType: "PlacePicker",
      isRequired: true,
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          ...val,
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            address: "",
          }));
        }
      },
      colNum: 6,
      value: inputData?.address,
      formError: inputError.address,
    },
    {
      title: "Instructions",
      inputType: "AdminInput",
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          instructions: val,
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            instructions: "",
          }));
        }
      },
      multiline: true,
      rows: 1,
      colNum: 6,
      value: inputData?.instructions,
      formError: inputError.instructions,
    },
    {
      title: "Description",
      inputType: "AdminInput",
      isRequired: true,
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          description: val,
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            description: "",
          }));
        }
      },
      type: "text",
      multiline: true,
      rows: 3,
      colNum: 12,
      maximumLength: 600,
      value: inputData?.description,
      formError: inputError.description,
    },
    {
      title: "Service available at home",
      inputType: "AdminRadio",
      isRequired: true,
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          serviceProvideAtHome: val,
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            serviceProvideAtHome: "",
          }));
        }
      },
      colNum: 6,
      value:
        inputData?.serviceProvideAtHome === "true" ||
        inputData?.serviceProvideAtHome === true
          ? true
          : false,
      formError: inputError.serviceProvideAtHome,
      radioBtnData: [
        { id: true, label: "Yes" },
        { id: false, label: "No" },
      ],
    },
    {
      label: "Save as draft",
      inputType: "AdminCheckbox",
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          inDraft: val,
        }));
      },
      colNum: 4,
      value: inputData?.inDraft ? inputData?.inDraft : false,
      position: "end",
    },
    {
      title: "Upload Image (Max 10)",
      inputType: "ImageUpload",
      onChange: onChangeFile,
      removeProfile: removeProfile,
      multiple: true,
      colNum: 12,
      value: inputData?.servicePhotosUrl,
      formError: inputError.servicePhotosUrl,
    },
  ];

  const handleSubmit = () => {
    if (!inputData.title) {
      setInputError((inputError) => ({
        ...inputError,
        title: "Please add title",
      }));
      return;
    }
    if (!inputData.categoryUid) {
      setInputError((inputError) => ({
        ...inputError,
        categoryUid: "Please add category",
      }));
      return;
    }
    if (inputData?.categoryUid === otherId && !inputData?.categoryName) {
      setInputError((inputError) => ({
        ...inputError,
        categoryName: "Please enter category name",
      }));
      return;
    }
    if (!inputData.price) {
      setInputError((inputError) => ({
        ...inputError,
        price: "Please add price",
      }));
      return;
    }
    if (!inputData.priceType) {
      setInputError((inputError) => ({
        ...inputError,
        priceType: "Please add price type",
      }));
      return;
    }
    if (!inputData.duration) {
      setInputError((inputError) => ({
        ...inputError,
        duration: "Please add duration",
      }));
      return;
    }
    if (!inputData.sellerId) {
      setInputError((inputError) => ({
        ...inputError,
        sellerId: "Please add service provider",
      }));
      return;
    }
    if (!inputData.address) {
      setInputError((inputError) => ({
        ...inputError,
        address: "Please add address",
      }));
      return;
    }
    if (!inputData.description) {
      setInputError((inputError) => ({
        ...inputError,
        description: "Please add description",
      }));
      return;
    }
    if (!inputData.serviceProvideAtHome) {
      setInputError((inputError) => ({
        ...inputError,
        serviceProvideAtHome: "Please select Service Provide At Home",
      }));
      return;
    }

    if (inputData.serviceProvideAtHome === "false") {
      inputData.serviceProvideAtHome = false;
    }

    if (inputData.serviceProvideAtHome === "true") {
      inputData.serviceProvideAtHome = true;
    }

    if (id) {
      dispatch(updateService(inputData, navigate));
    } else {
      dispatch(createService(inputData, navigate));
    }
  };

  const handleBack = () => {
    navigate("/dashboard/services");
    dispatch(clearStateData(), clearImageData());
  };

  return (
    <InputForm
      formTitle={"Add Service"}
      // removeProfile={removeProfile}
      inputData={addInput}
      btnSave={handleSubmit}
      isLoading={isLoading}
      btnBack={handleBack}
    />
  );
}
