import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import image from "../../assets/img/logo.png";
import AdminButton from "../../components/adminButton";
import AdminInput from "../../components/adminInput";
import { useState } from "react";
import { color } from "../../theme/color";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../store/slice/auth";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function ForgotPassword() {
  const path = window.location.origin;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState({});
  const handleSubmit = () => {
    const validEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );

    if (!email) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter your email address",
      }));
      return;
    } else if (!email.includes("@")) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    } else if (!email.includes(".")) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    } else if (!validEmail.test(email)) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    }

    const requestParam = {
      email: email,
      link: `${path}/resetPassword/`
    };

    dispatch(forgotPassword(requestParam));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        alignItems="center"
        justifyContent="center"
        sx={{
          padding: { xs: false, sm: false, md: 10, lg: 10 },
          flexGrow: 1,
          backgroundColor:  color.main,
          display: "flex",
          flexDirection: "column",
          height: "95vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CssBaseline />
        <Grid item xs={12} sm={8} md={4} component={Paper}>
          <Box
            sx={{
              my: 8,
              mx: 10,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ mx: "auto", width: 150 }}>
              <img
                src={image}
                alt="logo"
                style={{ width: "100%", borderRadius: "50%" }}
              />
            </Box>
            <Typography
              sx={{ textAlign: "center" }}
              component="h1"
              variant="h5"
            >
              Forgot Password
            </Typography>
            <Box noValidate sx={{ mt: 1 }}>
              <AdminInput
                title="Email Address"
                type="email"
                value={email}
                onChange={(val) => {
                  if (val) {
                    setEmail(val);
                    setFormError((formError) => ({
                      ...formError,
                      email: "",
                    }));
                  }
                  else {
                    setEmail('');
                  }
                }}
                formError={formError.email}
              />
              {/* <Link
                sx={{
                  float: "right",
                  textDecoration: "none",
                  fontSize: 15,
                  color: "#3BB0BA", 
                  fontWeight: 600,
                }}
                variant="body2"
              >
                Forgot password?
              </Link> */}
              <AdminButton
                onClick={handleSubmit}
                title={"Reset Password"}
                fullWidth="true"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
