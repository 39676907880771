import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Typography,
} from "@mui/material";
import {
  deleteService,
  getTransactionsList,
  loadingStart,
} from "../../store/slice/categories";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "../../components/adminDialog/confirmDialog";
import { Card } from "@mui/material";
import ServiceFilterDiaglog from "../../components/adminDialog/serviceFilterDiaglog";
import moment from "moment";
export default function Transaction() {
  var page = 1;
  var perPage = 5;
  const dispatch = useDispatch();
  const { transactionsData, transactionsCount, isLoading } = useSelector(
    (state) => state.categories
  );
  const [filterShow, setFilterShow] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [pagecheck, setPageCheck] = useState(1);
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortColumn, setSortColumn] = useState("");
  const [searchValue, setSearchValue] = useState({});
  const [showDialog, setShowDialog] = useState(null);


  useEffect(() => {
    fetchTransactionsList(page);
    dispatch(loadingStart());
  }, []);

  useEffect(() => {
    setTotalCount(transactionsCount);
  }, [transactionsCount]);

  useEffect(() => {
    fetchTransactionsList(page, perPageRows, sortColumn, sortOrder, searchValue);
  }, [searchValue]);

  const fetchTransactionsList = (page, numberOfRows) => {
    var data = {
      page: page ? page : 1,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      title: searchValue.searchValue,
      categoryUid: searchValue.categoryUid,
    };
    dispatch(getTransactionsList(data));
  };

  const handleDelete = (value) => {
    dispatch(deleteService(value));
  };

  const columns = [
    {
      name: "serviceDetails",
      label: "Service",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value.serviceTitle ? value.serviceTitle : "-"}</Typography>
          );
        },
      },
    },
    {
      name: "sellerDetails",
      label: "Seller",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography
                sx={{
                  fontSize: 12,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                }}
              >
                {value.sellerName ? value.sellerName : "-"}
              </Typography>
          );
        },
      },
    },
    {
      name: "buyerDetails",
      label: "Buyer",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography
                sx={{
                  fontSize: 12,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                }}
              >
                {value.buyerName ? value.buyerName : "-"}
              </Typography>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? moment(value).format("DD/MM/YYYY") : "-"}</Typography>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? value : "-"}</Typography>
          );
        },
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? value : "-"}</Typography>
          );
        },
      },
    },
    {
      name: "currency",
      label: "Currency",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? value : "-"}</Typography>
          );
        },
      },
    },
    {
      name: "paymentStatus",
      label: "Payment Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? value : "-"}</Typography>
          );
        },
      },
    },
    {
      name: "isRefunds",
      label: "Refunds",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? 'Yes' : "No"}</Typography>
          );
        },
      },
    },
    {
      name: "sellerPaymentStatus",
      label: "Seller Payment Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? 'Yes' : "No"}</Typography>
          );
        },
      },
    },
  ];

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "multiselect",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [5, 10, 15, 100],
    rowsPerPage: 5,
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Total item per page ",
        displayRows: "of",
      },
      body: {
        noMatch: "No Record Found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onColumnSortChange: (changedColumn, direction) => {
      let sortingOrder = direction === "asc" ? 1 : -1;
      setSortOrder(sortingOrder);
      setSortColumn(changedColumn);
      fetchTransactionsList(page, "", changedColumn, sortingOrder);
    },
    onChangePage(currentPage) {
      if (currentPage >= pagecheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        fetchTransactionsList(page, "", "", "");
      } else {
        if (currentPage > 0) {
          page = currentPage + 1;
        }
        setPageCheck(currentPage);
        fetchTransactionsList(page, "", "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      fetchTransactionsList(page, numberOfRows, "", "");
    },
  };

  return (
    <>
    {!isLoading ? (
      <div className="App wrapper">
        {transactionsData && transactionsData.length > 0 ? (
          <>
            <MUIDataTable
              title={"Transactions"}
              data={transactionsData}
              columns={columns}
              options={options}
            />
          </>
        ) : (
          <>
            <Card
              style={{
                textAlign: "center",
                backgroundColor: "rgb(243 241 241)",
              }}
            >
              <Typography sx={{ fontSize: 24, fontWeight: 500, py: "3rem" }}>
                No Record Found
              </Typography>
            </Card>
          </>
        )}
      </div>
    ) : (
      ""
    )}
    {showDialog && (
      <ConfirmDialog
        open={showDialog.show}
        handleClose={() => {
          setShowDialog((showDialog) => ({
            ...showDialog,
            show: false,
          }));
        }}
        title={"Delete Service"}
        content={"Are you sure you want to delete this Service?"}
        handleSubmit={() => {
          setShowDialog((showDialog) => ({
            ...showDialog,
            show: false,
          }));
          handleDelete(showDialog.id);
        }}
      />
    )}

    <ServiceFilterDiaglog
      open={filterShow}
      handleClose={() => setFilterShow(false)}
      handleSave={(val) => {
        setSearchValue((searchValue) => ({
          ...searchValue,
          ...val,
        }));
      }}
    />
  </>
  );
}
