import { Box, Card, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import AdminInput from "../../components/adminInput";
import AdminButton from "../../components/adminButton";
import { userAccountdelition } from "../../store/slice/categories";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/img/logo.png";

const OtpPage = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const location = useLocation();
  console.log(location?.state?.email, "locationijfdiasdfj");

  const handleSubmit = () => {
    if (!formData?.otp) {
      setFormError((formError) => ({
        ...formError,
        otp: "Please enter 6-digit otp.",
      }));
      return;
    }
    const data = {
      email: location?.state?.email ? location?.state?.email : "",
      phoneNumber: location?.state?.phoneNumber
        ? location?.state?.phoneNumber
        : "",
      type: 2,
      verificationCode: formData?.otp,
    };

    dispatch(userAccountdelition(data));
  };

  return (
    <div
      style={{
        background: "black",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container maxWidth="sm">
        <Card sx={{ p: 4 }}>
          <Stack direction="row" alignItems="left" justifyContent="left">
          <Grid container display={"flex"} justifyContent={"center"}>
          <Box sx={{ mx: "auto", width: 150 }}>
                <img
                  src={Logo}
                  alt="logo"
                  style={{ width: "100%", borderRadius: "50%" }}
                />
              </Box>
            <Typography sx={{ fontWeight: 600, fontSize: 25 }}>
            
              Please enter the One-Time Password to verify your account
            </Typography>
          </Grid>
          
          </Stack>
          <Grid container display={"flex"} justifyContent={"center"}>
            <Typography sx={{ fontSize: "1rem", my: 1 }}>
              A one time password has been sent to your
              {location?.state?.email
                ? `email ${location?.state?.email}`
                : `phone ${location?.state?.phoneNumber}`}
            </Typography>
          </Grid>
          <Grid container display={"flex"} justifyContent={"left"}>
            <AdminInput
              title="OTP"
              maxLength={6}
              type="text"
              value={formData?.otp}
              onChange={(val) => {
                const onlyNums = val.replace(/[^0-9]/g, "");

                setFormData((formData) => ({
                  ...formData,
                  otp: onlyNums,
                }));
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    otp: "",
                  }));
                }
              }}
              formError={formError?.otp}
            />
          </Grid>
          <Grid container display={"flex"} justifyContent={"right"}>
            <AdminButton title="Submit" onClick={handleSubmit} />
          </Grid>
        </Card>
      </Container>
    </div>
  );
};

export default OtpPage;
