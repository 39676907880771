import { Typography } from "@mui/material";
import React from "react";
import "./index.css";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css'

export default function PhoneInputComponent(props) {
  const { formError, value,onChange } = props;

  return (
    <div style={{border:"1px solid #000",borderRadius:'6px' }}>
      <PhoneInput
        country={"ca"}
        placeholder={""}
        specialLabel=""
        onChange={onChange}
        value={value}
        inputStyle={{
          width: "100%",
          border: "1px solid #000",
          height: "42px",
          fontSize: "19px",
        }}
      />
      <Typography sx={{ color: "red", fontSize: 12, ml: 1 }}>
        {formError ? formError : ""}
      </Typography>
    </div>
  );
}
