import { TextField, Typography } from "@mui/material";
import React from "react";
import "./index.css";
import { color } from "../../theme/color";
import InputAdornment from "@mui/material/InputAdornment";

export default function AdminInput(props) {
  const {
    title,
    type,
    formError,
    value,
    onChange,
    endIcon,
    PasswordShowClick,
    onBlur,
    autoFocus,
    multiline,
    rows,
    maxLength,
    startIcon,
    placeholder,
  } = props;

  const styles = (theme) => ({
    textField: {
      margin: 0,
    },
  });

  return (
    <>
      <TextField
        margin="normal"
        required
        fullWidth
        inputProps={{
          style: {
            height: !multiline ? "9px" : "",
            // padding: '0 14px',
            // margin: 0,
          },
          className: styles.input,
        }}
        type={type}
        InputLabelProps={{
          style: { color: color.main, top: "-7px" },
        }}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        value={value}
        id={type}
        sx={{ mt: title ? 2 : 0 }}
        label={title}
        placeholder={placeholder}
        name={type}
        autoComplete={type}
        autoFocus={autoFocus}
        multiline={multiline}
        inputProps={{ maxLength: maxLength }}
        rows={rows}
        InputProps={{
          startAdornment: startIcon ? (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ) : (
            ""
          ),
          endAdornment: (
            <InputAdornment onClick={PasswordShowClick} position="end">
              {endIcon}
            </InputAdornment>
          ),
        }}
      />
      <Typography sx={{ color: "red", fontSize: 12, ml: 1 }}>
        {formError ? formError : ""}
      </Typography>
    </>
  );
}
