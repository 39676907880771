import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DialogTitle } from "@mui/material";
import AdminButton from "../../adminButton";
import AdminSelect from "../../adminSelect";
import { IconButton } from "@mui/material";
import { Icon } from "@mui/material";
import { selectCategory } from "../../../store/slice/categories";
import { useDispatch, useSelector } from "react-redux";

export default function ServiceFilterDiaglog(props) {
  const { open, handleClose, handleSave } = props;
  const [userFilter, setUserFilter] = useState({});
  const dispatch = useDispatch();
  const { selectCategoryData } = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(selectCategory());
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Service Filter</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Icon
            icon="ic:baseline-close"
            sx={{
              color: "#000",
            }}
          />
        </IconButton>
        <DialogContent>
          <AdminSelect
            title={"Category Name"}
            menuData={
              selectCategoryData &&
              selectCategoryData?.map((item) => ({
                label: `${item.categoryName}`,
                id: item.categoryUid,
              }))
            }
            onChange={(val) => {
              setUserFilter((userFilter) => ({
                ...userFilter,
                categoryUid: val,
              }));
            }}
          />
        </DialogContent>
        <DialogActions>
          <AdminButton
            title={"Clear Filter"}
            onClick={() => {
              userFilter.categoryUid = "";
              handleSave(userFilter);
              handleClose();
            }}
          />
          <AdminButton
            title={"Save"}
            onClick={() => {
              handleSave(userFilter);
              handleClose();
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
