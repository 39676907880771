import React, { useEffect, useState } from "react";
import InputForm from "../../components/inputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  addCategory,
  categoryDetails,
  clearStateData,
  clearImageData,
  loadingEnd,
  loadingStart,
  updateCategory,
} from "../../store/slice/categories";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import instance from "../../services";
import { SKILIIAPI,SERVER_URL } from "../../services/apiConstant";

const initialValues = {
  categoryName: "",
  description: "",
  categoryImage: "",
};

export default function AddForm(props) {
  const [inputData, setInputData] = useState(initialValues);
  const { categoriesData, isLoading } = useSelector(
    (state) => state.categories
  );

  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const { id } = useParams();
  const [inputError, setInputError] = useState({});

  useEffect(() => {
    dispatch(clearStateData());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(categoryDetails(id));
    }
  }, [id]);

  const onChangeFile = (formData, item) => {
    dispatch(loadingStart());
    instance
      .post(
        `${SERVER_URL}${SKILIIAPI.categories.categoryImageUpload}` + "cacategoryImages",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        let result = response.data;

        if (result.status === 1) {
          let filename = response?.data?.data[0]?.fileName;
          let imageFullPath = response.data.data[0].path;
          setInputData((inputData) => ({
            ...inputData,
            categoryImage: filename,
            fileName: imageFullPath,
          }));
          dispatch(loadingEnd());
          toast.success(response.data.message);
        }
      })
      .catch(() => {
        ("error");
        dispatch(loadingEnd());
      });
  };

  useEffect(() => {
    if (id && categoriesData) {
      if (categoriesData) {
        setInputData(categoriesData);
      }
    }
  }, [id, categoriesData]);

  const removeProfile = () => {
    removeFileUpload();
  };

  const addInput = [
    {
      title: "Category Name",
      inputType: "AdminInput",
      isRequired: true,
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          categoryName: val,
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            categoryName: "",
          }));
        }
      },
      colNum: 12,
      value: inputData?.categoryName,
      formError: inputError.categoryName,
    },
    {
      title: "Category Description",
      inputType: "AdminInput",
      isRequired: true,
      onChange: (val) => {
        setInputData((inputData) => ({
          ...inputData,
          description: val,
        }));
        if (val) {
          setInputError((inputError) => ({
            ...inputError,
            description: "",
          }));
        }
      },
      multiline: true,
      rows: 3,
      colNum: 12,
      value: inputData?.description,
      formError: inputError.description,
    },
    {
      title: "Upload Image",
      inputType: "ImageUpload",
      removeProfile: removeProfile,
      isRequired: true,
      onChange: (event) => {
        onChangeFile(event, "categoryImage");
      },
      colNum: 12,
      value: inputData?.fileName,
      formError: inputError.categoryImage,
    },
  ];

  const removeFileUpload = () => {
    setInputData((inputData) => ({
      ...inputData,
      categoryImage: "",
      fileName: "",
    }));
  };

  const handleSubmit = () => {
    if (!inputData.categoryName) {
      setInputError((inputError) => ({
        ...inputError,
        categoryName: "Please add Category Name",
      }));
      return;
    }
    if (!inputData.description) {
      setInputError((inputError) => ({
        ...inputError,
        description: "Please add description Name",
      }));
      return;
    }
    if (!inputData.categoryImage) {
      setInputError((inputError) => ({
        ...inputError,
        categoryImage: toast.error("Please add category image"),
      }));
      return;
    }

    if (id) {
      dispatch(
        updateCategory(inputData, navigate("/dashboard/categories")),
        clearImageData()
      );
    } else {
      dispatch(
        addCategory(inputData, navigate("/dashboard/categories")),
        clearImageData()
      );
    }
  };

  const handleBack = () => {
    navigate("/dashboard/categories");
    dispatch(clearStateData(),clearImageData());
  };

  return (
    <InputForm
      formTitle={"Add Categories"}
      // removeProfile={removeProfile}
      inputData={addInput}
      btnSave={handleSubmit}
      isLoading={isLoading}
      btnBack={handleBack}
    />
  );
}
