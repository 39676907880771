import React from "react";
import { Grid } from "@mui/material";
import AdminInput from "../adminInput";
import AdminButton from "../adminButton";
import ImageUpload from "../imageUpload";
import AdminSelect from "../adminSelect";
import AdminAutocomplete from "../adminAutocomplete";
import PlacePickerComponent from "../placePickerComponent";
import AdminCheckbox from "../adminCheckbox";
import AdminRadio from "../adminRadio";
import PhoneInputComponent from "../phoneInputComponent";

export default function InputForm(props) {
  const { formTitle, inputData, btnSave, isLoading, btnBack } = props;

  return (
    <>
      <div className="App wrapper">
        <h2> {formTitle} </h2>

        <Grid container spacing={2}>
          {inputData &&
            inputData?.map((item, index) => {
              return !item.isNotShow ? (
                <Grid item md={item.colNum} xs={12}>
                  <span>{item.title}</span>
                  {item.isRequired ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                  {item.inputType === "AdminInput" ? (
                    <>
                      <AdminInput
                        type={item.type}
                        multiline={item.multiline}
                        rows={item.rows}
                        value={item.value}
                        onChange={item.onChange}
                        formError={item.formError}
                        maxLength={item.maximumLength ? item.maximumLength : 50}
                        startIcon={item.startIcon}
                      />
                    </>
                  ) : item.inputType === "AdminSelect" ? (
                    <>
                      {/* <span>{item.title}</span>
                      {item.isRequired ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                      <AdminSelect
                        menuData={item.menuData}
                        formError={item.formError}
                        value={item.value}
                        onChange={item.onChange}
                      />
                    </>
                  ) : item.inputType === "AdminAutocomplete" ? (
                    <>
                      {/* <span>{item.title}</span>
                      {item.isRequired ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                      <AdminAutocomplete
                        menuData={item.menuData}
                        handleSearch={item.handleSearch}
                        formError={item.formError}
                        value={item.value}
                        onChange={item.onChange}
                      />
                    </>
                  ) : item.inputType === "PlacePicker" ? (
                    <>
                      {/* <span>{item.title}</span>
                      {item.isRequired ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                      <PlacePickerComponent
                        formError={item.formError}
                        value={item.value}
                        onAddressChange={item.onChange}
                      />
                    </>
                  ) : item.inputType === "AdminCheckbox" ? (
                    <>
                      {/* <span>{item.title}</span>
                      {item.isRequired ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                      <AdminCheckbox
                        checked={item.value}
                        handleChange={item.onChange}
                        label={item.label}
                        position={item.position}
                      />
                    </>
                  ) : item.inputType === "AdminRadio" ? (
                    <>
                      {/* <span>{item.title}</span>
                      {item.isRequired ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                      <AdminRadio
                        title={item.label}
                        disabled={item.disabled}
                        radioBtnData={item.radioBtnData}
                        formError={item.formError}
                        value={item.value}
                        handleChange={item.onChange}
                      />
                    </>
                  ) : item.inputType === "PhoneInputComponent" ? (
                    <>
                      {/* <span>{item.title}</span>
                      {item.isRequired ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                      <PhoneInputComponent
                        title={item.label}
                        radioBtnData={item.radioBtnData}
                        formError={item.formError}
                        value={item.value}
                        onChange={item.onChange}
                      />
                    </>
                  ) : (
                    item.inputType === "ImageUpload" && (
                      <>
                        {/* <span>{item.title}</span>
                        {item.isRequired ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )} */}
                        <ImageUpload
                          removeProfile={item.removeProfile}
                          onChange={item.onChange}
                          uploadedImage={item?.value}
                          isLoading={isLoading}
                          multiple={item.multiple}
                        />
                      </>
                    )
                  )}
                </Grid>
              ) : (
                ""
              );
            })}
        </Grid>
        <Grid container spacing={0.6} sx={{ mt: 2 }}>
          <Grid item md={0}>
            <AdminButton title={"Back"} onClick={() => btnBack()} />
          </Grid>
          <Grid item md={0}>
            <AdminButton
              title={"Save"}
              onClick={() => btnSave()}
              maxLength={150}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
