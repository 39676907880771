import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import { DialogTitle } from "@mui/material";
import AdminButton from "../../adminButton";
import AdminSelect from "../../adminSelect";
import AdminInput from "../../adminInput";
import defaultProfile from "../../../assets/img/default.jpg";
import { Divider } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { Typography } from "@mui/material";
import { Avatar } from "@mui/material";
import { Grid } from "@mui/material";

export default function ViewdetailsDialog(props) {
  const { open, handleClose, data } = props;
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
        {"User Details"}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          style={{ margin: 15 }}
        >
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <Avatar
              alt="Remy Sharp"
              src={data.userProfile ? data.userProfile : defaultProfile}
              sx={{ width: 56, height: 56, textAlign: "center" }}
            />
          </Typography>
          <br />

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Name</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data.fullName ? data.fullName : "N/A"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Email</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data.email ? data.email : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Phone Number</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data.phoneNumber ? (
                  <>
                    {data.countryCode}
                    {data.phoneNumber}
                  </>
                ) : (
                  "N/A"
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Emergency Contact</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data.emergencyContact ? (
                  <>
                    {data.countryCode}
                    {data.emergencyContact}
                  </>
                ) : (
                  "N/A"
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Register Date</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data.registerDate ? data.registerDate : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>User Status</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.accountSuspended === 0 ? "Account Suspended" : "Active"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>Average Rating</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data.averageRating ? data.averageRating : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid>
              <Typography sx={{ mb: 1.5 }}>User Type</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5 }}>
                {data?.userType === 2 ? "Buyer" : "Multi User"}
              </Typography>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <Divider />

      <DialogActions>
        <AdminButton title={"Ok"} onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
}
