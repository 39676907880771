import { Navigate, useRoutes } from "react-router-dom";
// layouts

import AuthLayout from "../layout/auth";
// import Login from "../pages/auth/login";
import secureLocalStorage from "react-secure-storage";
import ForgotPassword from "../pages/auth/forgot";
import DashboardLayout from "../layout/dashboard";
import DashboardApp from "../pages/dashboard/dashboard";
import Login2 from "../pages/auth/login2";
import Services from "../pages/dashboard/services";
import ReportedServices from "../pages/dashboard/reportedServices";
import Categories from "../pages/dashboard/categories";
import AddForm from "../pages/dashboard/addForm";
import Transaction from "../pages/dashboard/transaction";
import Users from "../pages/dashboard/Users";
import ResetPassword from "../pages/auth/resetPassword";
import ChangePassword from "../pages/dashboard/changePassword";
import Page404 from "../pages/page404";
import Bookings from "../pages/dashboard/bookings";
import AddServices from "../pages/dashboard/addServices";
import AddUser from "../pages/dashboard/addUser";
import PrivacyPolicy from "../pages/other pages/privacyPolicy";
import TermsAndConditions from "../pages/other pages/termsAndConditions";
import AccountDeletion from "../pages/other pages/accountDeletion";
import OtpPage from "../pages/other pages/otpPage";
import ConnectedAccount from "../pages/dashboard/ConnectedAccount";
import AppConstant from "../pages/dashboard/AppConstant";
export default function Router() { 
  const isLoggedIn = secureLocalStorage.getItem(
    process.env.REACT_APP_AUTH_STORAGE_KEY
  );
  const routes = useRoutes([
    {
      path: "/dashboard",
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "app", element: <DashboardApp /> },
        { path: "categories", element: <Categories /> },
        { path: "add", element: <AddForm /> },
        { path: "categories/edit/:id", element: <AddForm /> },
        { path: "users", element: <Users /> },
        { path: "services", element: <Services /> },
        { path: "reportedServices", element: <ReportedServices /> },
        { path: "transaction", element: <Transaction /> },
        { path: "connected-account", element: <ConnectedAccount /> },
        { path: "app-constant", element: <AppConstant /> },
        { path: "changePassword", element: <ChangePassword /> },
        { path: "bookings", element: <Bookings /> },
        { path: "addServices", element: <AddServices /> },
        { path: "service/edit/:id", element: <AddServices /> },
        { path: "addUser", element: <AddUser /> },
        { path: "users/edit/:id", element: <AddUser /> },

        // { path: '*', element: <Page404/> },
      ],
    },
    {
      path: "/",
      element: !isLoggedIn ? <AuthLayout /> : <Navigate to="/dashboard/app" />,
      children: [
        { path: "login", element: <Login2 /> },
        { path: "forgotPassword", element: <ForgotPassword /> },
        { path: "resetPassword/:id", element: <ResetPassword /> },
        { path: "/", element: <Navigate to="/login" /> },
      ],
    },
    { path: "*", element: <Page404 /> },
    { path: "privacy-policy", element: <PrivacyPolicy /> },
    { path: "otp", element: <OtpPage /> },
    { path: "account-deletion", element: <AccountDeletion/> },
    { path: "term-conditions", element: <TermsAndConditions /> },
  
    // { path: "terms-conditions", element: <TermsAndConditions /> },
  ]);

  return routes;
}
