export const SKILIIAPI = {
  //auth
  auth: {
    login: "admin/loginForAdmin",
    forgotPassword: "admin/sendResetPasswordLink",
    resetPassword: "admin/resetPasswordForAdmin",
  },

  dashBoardForAdmin: "admin/dashBoardForAdmin",

  //categories
  categories: {
    list: "category/getCategoryList",
    addCategory: "category/addCategory",
    categoryDetails: "category/categoryDetails?categoryUid=",
    updateCategory: "category/updateCategory",
    deletes: "users/deletes",
    categoryImageUpload: "category/categoryImageUpload/",
    removeprofile: "users/deleteImage",
    changePassword: "admin/changePassword",
  },
  users: {
    list: "admin/getAllUsersListForAdmin",
    deleteUserAccount: "admin/deleteUserAccount/",
    userAccountSuspend: "admin/userAccountSuspendForAdmin",
    addUser: "admin/userRegistration",
    userDetails: "admin/getSingleUserByUid",
    updateUser: "users/updateUserProfile",
    userAccountDelation: "users/userDeleteByEmailOrNumber",
    connectedAccounts: "users/getStripeAccounts",
    deleteStripeAccount: "users/deleteStripeAccount",
    updateFees: "appConstant/updateFees"
  },
  service: {
    list: "admin/getServiceList",
    // deleteUserAccount: "admin/deleteUserAccount/",
    userAccountSuspend: "admin/userAccountSuspendForAdmin",
    deleteService: "admin/deleteService/",
    selectCategory: "category/selectCategory",
    serviceProvider: "admin/allServiceProviderList",
    serviceImagesUpload: "service/serviceImageUpload/serviceImages/image",
    createService: "service/createService",
    getServiceDetail: "service/getServiceDetail",
    updateService: "service/updatedService",

  },
  booking: {
    list: "admin/getAllBookingServices"
  },
  transactions: {
    list: "admin/getAllTransactionList "
  }
};

export const API_RESPONSE = {
  SUCCESS: 1,
  FAILURE: 0,
};

export const SERVER_URL = "https://api.skilii.com/produciton/"; //DEV IP
