import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import image from "../../assets/img/logo.png";
import { Icon } from "@iconify/react";
import AdminButton from "../../components/adminButton";
import AdminInput from "../../components/adminInput";
import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { color } from "../../theme/color";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/slice/auth";
import SpinnerComponent from "../../components/spinnerComponent";
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login2() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  let isLogin = secureLocalStorage.getItem(
    process.env.REACT_APP_AUTH_STORAGE_KEY
  );
  const { isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isLogin) {
      navigate("/", true);
    }
  }, []);

  const handleSubmit = () => {
    const validEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );

    if (!email) {
      setFormError((formError) => ({
        ...formError,
        email: "Please Enter Your Email Address",
      }));
      return;
    } else if (!email.includes("@")) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    } else if (!email.includes(".")) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    } else if (!validEmail.test(email)) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address",
      }));
      return;
    }
    if (!password) {
      setFormError((formError) => ({
        ...formError,
        password: "Please enter a password",
      }));
      return;
    }

    const requestParam = {
      email: email,
      password: password,
    };

    dispatch(login(requestParam, navigate));
  };

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Grid
          container
          component="main"
          alignItems="center"
          justifyContent="center"
          sx={{
            padding: { xs: false, sm: false, md: 10, lg: 10 },
            flexGrow: 1,
            backgroundColor: color.main,
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CssBaseline />
          <Grid item xs={12} sm={8} md={4} component={Paper}>
            <Box
              sx={{
                my: 8,
                mx: 10,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ mx: "auto", width: 150 }}>
                <img
                  src={image}
                  alt="logo"
                  style={{ width: "100%", borderRadius: "50%" }}
                />
              </Box>
              <Typography
                sx={{ textAlign: "center" }}
                component="h1"
                variant="h5"
              >
                Sign in
              </Typography>
              <Box noValidate sx={{ mt: 1 }}>
                <AdminInput
                  title="Email Address"
                  type="email"
                  value={email}
                  onChange={(val) => {
                    if (val) {
                      setEmail(val);
                      setFormError((formError) => ({
                        ...formError,
                        email: "",
                      }));
                    }
                    else {
                      setEmail('');
                    }
                  }}
                  formError={formError.email}

                />
                <AdminInput
                  title="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(val) => {
                    if (val) {
                      setPassword(val);
                      setFormError((formError) => ({
                        ...formError,
                        password: "",
                      }));
                    }
                    else {
                      setPassword('');
                    }
                  }}
                  formError={formError.password}
                  endIcon={
                    showPassword ? (
                      <Icon icon="tabler:eye" width="25" height="25" color="#9d9ea0" />
                    ) : (
                      <Icon icon="tabler:eye-off" width="25" height="25" color="#9d9ea0" />
                    )
                  }
                  PasswordShowClick={() => setShowPassword(!showPassword)}
                />

                <Link
                  sx={{
                    float: "right",
                    textDecoration: "none",
                    fontSize: 15,
                    color: color.main,
                    fontWeight: 600,
                  }}
                  variant="body2"
                  href="forgotPassword"
                >
                  Forgot password?
                </Link>
                <AdminButton
                  onClick={handleSubmit}
                  isLoading={isLoading}
                  title={"Sign IN"}
                  fullWidth="true"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      <SpinnerComponent open={isLoading}/>
    </>
  );
}
