import { useTheme } from "@mui/material/styles";
import { Grid, Container } from "@mui/material";
import AppWidgetSummary from "../../sections/@dashboard/app/AppWidgetSummary";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashBoardForAdmin } from "../../store/slice/categories";
import SpinnerComponent from "../../components/spinnerComponent";
// sections

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { dashBoardAdminData,isLoading } = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(dashBoardForAdmin());
  }, []);

  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Buyer"
              total={dashBoardAdminData?.totalBuyerCount}
              icon={"icon-park-solid:buy"}
              bgColor={"rgb(209, 233, 252)"}
              color={"rgb(16, 57, 150)"}
              backgroundImage={
                "rgba(16, 57, 150, 0) 0%, rgba(16, 57, 150, 0.24) 100%"
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total No Of Users"
              total={dashBoardAdminData?.totalNoOfUsers}
              color={"rgb(12, 83, 183)"}
              bgColor={"rgb(208, 242, 255)"}
              icon={"mdi:users"}
              backgroundImage={
                "rgba(12, 83, 183, 0) 0%, rgba(12, 83, 183, 0.24) 100%"
              }
            />
          </Grid>
        {/* </Grid>

        <Grid container spacing={3} sx={{ my: 2 }}> */}
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Seller"
              total={dashBoardAdminData?.totalSellerCount}
              color={"rgb(183, 129, 3);"}
              bgColor={"rgb(255, 247, 205)"}
              icon={"ant-design:windows-filled"}
              backgroundImage={
                "rgba(183, 129, 3, 0) 0%, rgba(183, 129, 3, 0.24) 100%"
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Services"
              total={dashBoardAdminData?.totalServicesCount}
              color={"rgb(122, 12, 46);"}
              bgColor={"rgb(255, 231, 217)"}
              icon={"ic:baseline-cleaning-services"}
              backgroundImage={
                "rgba(183, 33, 54, 0) 0%, rgba(183, 33, 54, 0.24) 100%"
              }
            />
          </Grid>
        </Grid>
      </Container>
      <SpinnerComponent open={isLoading}/>
    </>
  );
}
