import { TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import "./index.css";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";

export default function AdminAutocomplete(props) {
  const { handleSearch, menuData, formError, value, onChange } = props;
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  const getSelectedItem = () => {
    const item = menuData?.find((opt) => {
      if (opt.id === value) return opt;
    });
    return item || null;
  };

  const onEnter = () => {
    const item = menuData?.find((opt) => {
      if (opt.label.replace(/\s/g, '').toLowerCase() === searchValue.replace(/\s/g, '').toLowerCase()) return opt;
    });
    item ?? onChange(item);
  };

  return (
    <>
      <Autocomplete
        value={value ? getSelectedItem() : null}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        onInputChange={(val) => setSearchValue(val?.target.value)}
        options={menuData}
        // getOptionLabel={(option) => {
        //   return option.label;
        // }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onEnter();
          }
        }}
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              input: {
                height: 8,
              },
            }}
          />
        )}
      />
      <Typography sx={{ color: "red", fontSize: 12, ml: 1 }}>
        {formError ? formError : ""}
      </Typography>
    </>
  );
}
