import { Grid, Tooltip } from "@mui/material";
import AdminButton from "../../../components/adminButton";
import { Icon } from "@iconify/react";
import AdminInput from "../../../components/adminInput";
import { Link } from "react-router-dom";

export default function Filter(props) {
  const {
    addTooltipTitle,
    addBtn,
    path,
    onSearchChange,
    inputRef,
    openFilterDialog,
    addTitle,
    filterBtn,
    searchtitle
  } = props;
  // const [searchShow, setsearchShow] = useState(false);
  return (
    <>
      <Grid container spacing={0.6}>
        <Grid item xs={12} md={2} sx={{ my: "auto" }}>
          <AdminInput
            title={searchtitle}
            autoFocus={true}
            type="email"
            endIcon={<Icon icon="ion:search-outline" width={20} />}
            onChange={onSearchChange}
            inputRef={inputRef}
            // onBlur={() => {
            //   setsearchShow(false);
            // }}
          />
        </Grid>

        <Grid item xs={12} md={10} sx={{ my: "auto" }}>
          <Grid container spacing={0.6}>
            {filterBtn && (
              <Grid item xs={12} md={2} sx={{ ml: "auto" }}>
                <Tooltip title={addTooltipTitle}>
                  <AdminButton
                    onClick={openFilterDialog}
                    icon={<Icon icon="mdi:filter" width={20} />}
                    title={"filter"}
                    fullWidth="true"
                  />
                </Tooltip>
              </Grid>
            )}
            {addBtn ? (
              <>
                <Grid item xs={12} md={2} sx={{ ml: !filterBtn ? "auto" : "" }}>
                  <Tooltip title={addTooltipTitle}>
                    <Link
                      to={path}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <AdminButton title={addTitle} fullWidth="true" />
                    </Link>
                  </Tooltip>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        {/* {addBtn ? (
          <>
            <Grid item xs={12} md={2} sx={{ ml: "auto" }}>
              <Tooltip title={addTooltipTitle}>
                <Link
                  to={path}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <AdminButton title={addTitle} fullWidth="true" />
                </Link>
              </Tooltip>
            </Grid>
          </>
        ) : (
          ""
        )}
        {filterBtn && (
          <Grid item xs={12} md={2} sx={{ ml: "auto" }}>
            <AdminButton
              onClick={openFilterDialog}
              icon={<Icon icon="mdi:filter" width={20} />}
              title={"filter"}
              fullWidth="true"
            />
          </Grid>
        )} */}
      </Grid>
    </>
  );
}
