// component
import { Icon } from "@iconify/react";

const navConfig = [
  {
    title: "Home",
    path: "/dashboard/app",
    icon: <Icon icon="bxs:home" width={20} />,
  },
  {
    title: "Categories",
    path: "/dashboard/categories",
    icon: <Icon icon="carbon:collapse-categories" width={20} />,
  },
  {
    title: "Users",
    path: "/dashboard/Users",
    icon: <Icon icon="fa:users" width={20} />,
  },
  {
    title: "Services",
    path: "/dashboard/services",
    icon: <Icon icon="ic:round-cleaning-services" width={20} />,
  },
  // {
  //   title: "Reported Services",
  //   path: "/dashboard/reportedServices",
  //   icon: <Icon icon="material-symbols:report-off-rounded" width={20} />,
  // },
  {
    title: "Transactions",
    path: "/dashboard/transaction",
    icon: <Icon icon="ant-design:transaction-outlined" width={20} />,
  },
  {
    title: "Bookings",
    path: "/dashboard/bookings",
    icon: <Icon icon="uit:calender" width={20} />,
  },
  {
    title: "Connected accounts",
    path: "/dashboard/connected-account",
    icon: <Icon icon="fa:bank" width={20} />,
  },
  {
    title: "App constant",
    path: "/dashboard/app-constant",
    icon: <Icon icon="fa:gear" width={20} />,
  },
];

export default navConfig;
