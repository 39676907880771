import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { color } from "../../theme/color";
import { Helmet } from "react-helmet-async";
import Filter from "./subcomponents/filter";
import {
  deleteService,
  deleteUserAccount,
  getServiceList,
  loadingStart,
  userAccountSuspend,
} from "../../store/slice/categories";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import ConfirmDialog from "../../components/adminDialog/confirmDialog";
import { Card } from "@mui/material";
import ServiceFilterDiaglog from "../../components/adminDialog/serviceFilterDiaglog";
export default function ReportedServices() {
  var page = 1;
  var perPage = 5;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const { serviceListData, serviceListCount, isLoading } = useSelector(
    (state) => state.categories
  );
  const [filterShow, setFilterShow] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [pagecheck, setPageCheck] = useState(1);
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortColumn, setSortColumn] = useState("");
  const [searchValue, setSearchValue] = useState({});
  const [showDialog, setShowDialog] = useState(null);

  const handleClick = (tableMeta, event) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(tableMeta.rowIndex);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchCategoryList(page);
    dispatch(loadingStart());
  }, []);

  useEffect(() => {
    setTotalCount(serviceListCount);
  }, [serviceListCount]);

  useEffect(() => {
    fetchCategoryList(page, perPageRows, sortColumn, sortOrder, searchValue);
  }, [searchValue]);

  const fetchCategoryList = (page, numberOfRows) => {
    var data = {
      page: page ? page : 1,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      title: searchValue.searchValue,
      categoryUid: searchValue.categoryUid,
    };
    dispatch(getServiceList(data));
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDelete = (value) => {
    dispatch(deleteService(value));
  };

  const columns = [
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? value : "-"}</Typography>
          );
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        customBodyRender: (value) => {
          return (
            <Box sx={{ width: 400 }}>
              <Typography
                sx={{
                  fontSize: 12,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                }}
              >
                {value ? value : "-"}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "priceType",
      label: "PriceType",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? value : "-"}</Typography>
          );
        },
      },
    },

    {
      name: "price",
      label: "Price",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? value : "-"}</Typography>
          );
        },
      },
    },
    {
      name: "rating",
      label: "Rating",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? value : "-"}</Typography>
          );
        },
      },
    },
    {
      name: "serviceUid",
      label: "Action",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Button
                onClick={(event) => handleClick(tableMeta, event)}
                aria-describedby={id}
              >
                <Icon
                  icon="ph:dots-three-circle-vertical-fill"
                  color={color.main}
                  width="30"
                  height="30"
                />
              </Button>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        const id = serviceListData[selectedRowIndex].serviceUid;
                        setShowDialog((showDialog) => ({
                          ...showDialog,
                          show: true,
                          id: id,
                        }));
                      }}
                    >
                      <ListItemIcon>
                        <Icon
                          icon="ic:baseline-delete"
                          color="red"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Delete"
                        style={{ marginLeft: "-1rem" }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
            </>
          );
        },
      },
    },
  ];

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "multiselect",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [5, 10, 15, 100],
    rowsPerPage: 5,
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Total item per page ",
        displayRows: "of",
      },
      body: {
        noMatch: "No Record Found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onColumnSortChange: (changedColumn, direction) => {
      let sortingOrder = direction === "asc" ? 1 : -1;
      setSortOrder(sortingOrder);
      setSortColumn(changedColumn);
      fetchCategoryList(page, "", changedColumn, sortingOrder);
    },
    onChangePage(currentPage) {
      if (currentPage >= pagecheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        fetchCategoryList(page, "", "", "");
      } else {
        if (currentPage > 0) {
          page = currentPage + 1;
        }
        setPageCheck(currentPage);
        fetchCategoryList(page, "", "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      //localStorage.setItem("socialListRowPerPage", numberOfRows);
      setNumberOfRows(numberOfRows);
      fetchCategoryList(page, numberOfRows, "", "");
    },
  };

  return (
    <>
    {!isLoading ? (
      <div className="App wrapper">
        <Filter
          filterBtn={true}
          endIcon={<Icon icon="ic:outline-plus" color="black" />}
          onSearchChange={(val) => {
            setSearchValue((searchValue) => ({
              ...searchValue,
              searchValue: val,
            }));
          }}
          openFilterDialog={() => setFilterShow(true)}
          value={searchValue.searchValue}
        />
        {serviceListData && serviceListData.length > 0 ? (
          <>
            <MUIDataTable
              title={"Services List"}
              data={serviceListData}
              columns={columns}
              options={options}
            />
          </>
        ) : (
          <>
            <Card
              style={{
                textAlign: "center",
                backgroundColor: "rgb(243 241 241)",
              }}
            >
              <Typography sx={{ fontSize: 24, fontWeight: 500, py: "3rem" }}>
                No Record Found
              </Typography>
            </Card>
          </>
        )}
      </div>
    ) : (
      ""
    )}
    {showDialog && (
      <ConfirmDialog
        open={showDialog.show}
        handleClose={() => {
          setShowDialog((showDialog) => ({
            ...showDialog,
            show: false,
          }));
        }}
        title={"Delete Service"}
        content={"Are you sure you want to delete this Service?"}
        handleSubmit={() => {
          setShowDialog((showDialog) => ({
            ...showDialog,
            show: false,
          }));
          handleClose();
          handleDelete(showDialog.id);
        }}
      />
    )}

    <ServiceFilterDiaglog
      open={filterShow}
      handleClose={() => setFilterShow(false)}
      handleSave={(val) => {
        setSearchValue((searchValue) => ({
          ...searchValue,
          ...val,
        }));
      }}
    />
  </>
  );
}
