import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import auth from "./slice/auth";
import categories from "./slice/categories";
const rootReducer = combineReducers({
  auth,
  categories,
});
const store = configureStore({
  reducer: rootReducer,
});
export default store;
