import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { color } from "../../theme/color";
import defaultProfile from "../../assets/img/default.jpg";
import Filter from "./subcomponents/filter";
import { Box } from "@mui/material";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsersList,
  deleteUserAccount,
  userAccountSuspend,
  loadingStart,
} from "../../store/slice/categories";
import ConfirmDialog from "../../components/adminDialog/confirmDialog";
import { Card } from "@mui/material";
import FilterDialog from "../../components/adminDialog/userFilterDialog";
import UserFilterDialog from "../../components/adminDialog/userFilterDialog";
import ViewdetailsDialog from "../../components/adminDialog/viewdetailsDialog";
import SpinnerComponent from "../../components/spinnerComponent";
import { useNavigate } from "react-router-dom";
export default function Users() {
  var page = 1;
  var perPage = 5;
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const { userslistData, totalUsersCount, isLoading } = useSelector(
    (state) => state.categories
  );
  const [totalCount, setTotalCount] = useState();
  const [pagecheck, setPageCheck] = useState(1);
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [filterShow, setFilterShow] = useState(false);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortColumn, setSortColumn] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [showDialog, setShowDialog] = useState(null);
  const [showUserDialog, setshowUserDialog] = useState(null);

  const handleClick = (tableMeta, event) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(tableMeta.rowIndex);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchCategoryList(page);
    dispatch(loadingStart());
  }, []);

  useEffect(() => {
    setTotalCount(totalUsersCount);
  }, [totalUsersCount]);

  useEffect(() => {
    fetchCategoryList(page, perPageRows, sortColumn, sortOrder, searchValue);
  }, [searchValue]);

  const fetchCategoryList = (page, numberOfRows) => {
    var data = {
      page: page ? page : 1,
      perPage: numberOfRows ? numberOfRows : perPageRows,
      search: searchValue.searchValue,
      userType: searchValue.userType,
      userStatus: searchValue.userStatus,
    };
    dispatch(getAllUsersList(data));
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDelete = (value) => {
    const data = {
      page: page,
      perPageRows: perPageRows,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      searchValue: searchValue,
    };
    dispatch(deleteUserAccount(value,fetchCategoryList,data));
  };

  const handleSuspendaccount = (value) => {
    dispatch(userAccountSuspend(value));
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/users/edit/${id}`);
  };

  const columns = [
    {
      name: "userProfile",
      label: "Profile Image",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <>
              <Box sx={{ mx: 2, width: 40, height: 40 }}>
                <img
                  src={value ? value : defaultProfile}
                  alt="logo"
                  style={{ width: "100%" }}
                />
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "fullName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? value : "-"}</Typography>
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",

      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? value : "-"}</Typography>
          );
        },
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>{value ? value : "-"}</Typography>
          );
        },
      },
    },
    {
      name: "userType",
      label: "User Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>
              {value === 2 ? "Buyer" : "Multi User"}
            </Typography>
          );
        },
      },
    },
    {
      name: "accountSuspended",
      label: "User Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography sx={{ fontSize: 12 }}>
              {value === 0 ? "Account Suspended" : "Active"}
            </Typography>
          );
        },
      },
    },
    {
      name: "userUid",
      label: "Action",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Button
                onClick={(event) => handleClick(tableMeta, event)}
                aria-describedby={id}
              >
                <Icon
                  icon="ph:dots-three-circle-vertical-fill"
                  color={color.main}
                  width="30"
                  height="30"
                />
              </Button>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <List>
                <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        const id =
                        userslistData[selectedRowIndex].userUid;
                        handleEdit(id);
                      }}
                    >
                      <ListItemIcon>
                        <Icon
                          icon="ri:edit-fill"
                          color="#21618C"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Edit"
                        style={{ marginLeft: "-1rem" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        const data = {
                          userUid: userslistData[selectedRowIndex].userUid,
                          status:
                            userslistData[selectedRowIndex].accountSuspended ===
                            1
                              ? 0
                              : 1,
                        };
                        setShowDialog((showDialog) => ({
                          ...showDialog,
                          show: true,
                          data: data,
                          type: "suspendaccount",
                          title:
                            userslistData[selectedRowIndex].accountSuspended ===
                            1
                              ? "Suspend User"
                              : "Active User",
                          content:
                            userslistData[selectedRowIndex].accountSuspended ===
                            1
                              ? "Are you sure you want to Suspend this User?"
                              : "Are you sure you want to Active this User?",
                        }));
                      }}
                    >
                      <ListItemIcon>
                        <Icon
                          icon="zondicons:block"
                          color="#21618C"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Suspend Account"
                        style={{ marginLeft: "-1rem" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        setshowUserDialog((showUserDialog) => ({
                          ...showUserDialog,
                          show: true,
                          data: userslistData[selectedRowIndex],
                        }));
                      }}
                    >
                      <ListItemIcon>
                        <Icon
                          icon="bi:eye-fill"
                          color="green"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="View Detail"
                        style={{ marginLeft: "-1rem" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        const id = userslistData[selectedRowIndex].userUid;
                        // handleDelete(id);
                        setShowDialog((showDialog) => ({
                          ...showDialog,
                          show: true,
                          data: id,
                          type: "delete",
                          title: "Delete User",
                          content: "Are you sure you want to delete this User?",
                        }));
                      }}
                    >
                      <ListItemIcon>
                        <Icon
                          icon="ic:baseline-delete"
                          color="red"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Delete"
                        style={{ marginLeft: "-1rem" }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
            </>
          );
        },
      },
    },
  ];

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "multiselect",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [5, 10, 15, 100],
    rowsPerPage: 5,
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Total item per page ",
        displayRows: "of",
      },
      body: {
        noMatch: "No Record Found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    onColumnSortChange: (changedColumn, direction) => {
      let sortingOrder = direction === "asc" ? 1 : -1;
      setSortOrder(sortingOrder);
      setSortColumn(changedColumn);
      fetchCategoryList(page, "", changedColumn, sortingOrder);
    },
    onChangePage(currentPage) {
      if (currentPage >= pagecheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        fetchCategoryList(page, "", "", "");
      } else {
        if (currentPage > 0) {
          page = currentPage + 1;
        }
        setPageCheck(currentPage);
        fetchCategoryList(page, "", "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      //localStorage.setItem("socialListRowPerPage", numberOfRows);
      setNumberOfRows(numberOfRows);
      fetchCategoryList(page, numberOfRows, "", "");
    },
  };

  return (
    <>
      {!isLoading ? (
        <div className="App wrapper">
          <Filter
            filterBtn={true}
            endIcon={<Icon icon="ic:outline-plus" color="black" />}
            openFilterDialog={() => setFilterShow(true)}
            addBtn={true}
            addTitle="Add User"
            searchtitle="Search Here"
            path={"/dashboard/addUser"}
            onSearchChange={(val) => {
              setSearchValue((searchValue) => ({
                ...searchValue,
                searchValue: val,
              }));
            }}
            value={searchValue.searchValue}
          />
          {userslistData && userslistData.length > 0 ? (
            <>
              <MUIDataTable
                title={"Users List"}
                data={userslistData}
                columns={columns}
                options={options}
              />
            </>
          ) : (
            <>
              <Card
                style={{
                  textAlign: "center",
                  backgroundColor: "rgb(243 241 241)",
                }}
              >
                <Typography sx={{ fontSize: 24, fontWeight: 500, py: "3rem" }}>
                  No Record Found
                </Typography>
              </Card>
            </>
          )}
        </div>
      ) : (
        ""
      )}

      {showDialog && (
        <ConfirmDialog
          open={showDialog.show}
          handleClose={() => {
            setShowDialog((showDialog) => ({
              ...showDialog,
              show: false,
            }));
          }}
          title={showDialog.title}
          content={showDialog.content}
          handleSubmit={() => {
            setShowDialog((showDialog) => ({
              ...showDialog,
              show: false,
            }));
            handleClose();
            if (showDialog.type === "suspendaccount") {
              handleSuspendaccount(showDialog.data);
            } else {
              handleDelete(showDialog.data);
            }
          }}
        />
      )}

      <UserFilterDialog
        open={filterShow}
        handleClose={() => setFilterShow(false)}
        handleSave={(val) =>
          setSearchValue((searchValue) => ({
            ...searchValue,
            ...val,
          }))
        }
      />

<SpinnerComponent open={isLoading} />

      {showUserDialog && (
        <ViewdetailsDialog
          open={showUserDialog.show}
          handleClose={() => {
            setshowUserDialog((showUserDialog) => ({
              ...showUserDialog,
              show: false,
            }));
          }}
          data={showUserDialog.data}
        />
      )}
    </>
  );
}
